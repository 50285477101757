<template>
  <section class="not-permitted row justify-center items-center" aid="no-roles">
    <div class="column">
      <span class="title">Sorry, we can't let you see this page.</span>
      <span class="title">Something about permissions...</span>
      <span class="subtitle q-mt-xl">
        You should probably speak to the person who sent you here or
        <span class="link-clr pointer underline-on-hover" @click="handleNoPermissions">Go back</span>
      </span>
    </div>
    <runai-svg-icon name="403" size="500px" />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// cmps
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

// stores
import { usePermissionStore } from "@/stores/permissions.store";
import { useAuthStore } from "@/stores/auth.store";

export default defineComponent({
  components: {
    RunaiSvgIcon,
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
      authStore: useAuthStore(),
    };
  },
  computed: {
    hasNoPermissions(): boolean {
      return this.permissionStore.userHasNoPermissions();
    },
  },
  methods: {
    handleNoPermissions() {
      if (this.hasNoPermissions) {
        this.authStore.logout();
      } else {
        this.$router.back();
      }
    },
  },
});
</script>

<style scoped lang="scss">
.not-permitted {
  .title {
    font-size: 40px;
    color: $black-70;
  }

  .subtitle {
    font-size: 18px;
    color: $black-70;
  }

  .link {
    color: $primary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

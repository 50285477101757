import { ROLE_ROUTE_NAMES } from "@/router/role.routes/role.routes.names";
import { APPLICATIONS_ROUTE_NAMES } from "@/router/applications.routes/applications.routes.names";
import { SETTINGS_ROUTE_NAMES } from "@/router/settings.routes/settings.routes.names";
import { POLICIES_ROUTE_NAMES } from "@/router/policy.routes/policy.routes.names";
import { USER_ROUTES_NAMES } from "@/router/users.routes/users.routes.names";
import { DASHBOARD_ROUTE_NAMES } from "@/router/dashboard.routes/dashboard.routes.names";
import { COMPUTE_RESOURCE_ROUTE_NAMES } from "@/router/compute-resource.routes/compute-resource.routes.names";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { WORKLOAD_TEMPLATE_ROUTE_NAMES } from "@/router/workload-template.routes/workload-template.routes.names";
import { ENVIRONMENT_ROUTE_NAMES } from "@/router/environment.routes/environment.routes.names";
import { DATA_SOURCE_ROUTE_NAMES } from "@/router/data-source.routes/data-source.routes.names";
import { CREDENTIAL_ROUTE_NAMES } from "@/router/credential.routes/credential.routes.names";
import { CLUSTER_ROUTE_NAMES } from "@/router/cluster.routes/cluster.routes.names";
import { NODE_ROUTE_NAMES } from "@/router/node.routes/node.routes.names";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { AUDIT_ROUTE_NAMES } from "@/router/audit.routes/audit.routes.names";
import { MODEL_SPEC_NAMES } from "@/router/model-spec.routes/model-spec.routes.names";

// models
import type { EBadgeLabel } from "@/models/setting.model";
import { SettingKeys } from "@/models/setting.model";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { ReportRoutesName } from "@/router/report.routes/report.routes.names";
import { EFeatureToggle } from "@/services/infra/unleash.service/unleash.model";

// IMPORTANT!
// when you add a new route, make sure to add parentSubject prop to the route.meta

export interface ISubjectRoutes {
  name: string;
  label: string;
  aid: string;
  stageBadge?: EBadgeLabel;
  subItems?: ISubjectRoutes[];
  resourceType?: ResourceType | ResourceType[];
  minPermittedActions?: Action[];
  featureFlagName?: SettingKeys; // settings feature flag
  featureToggleName?: EFeatureToggle; // unleash feature toggle
  hiddenForBranded?: boolean;
}

export interface IMenuItem {
  label: string;
  icon: string;
  value: EMenuSubjectNames;
  aid: EMenuSubjectNames;
  items: ISubjectRoutes[];
  isActive?: boolean;
}

export enum EMenuSubjectNames {
  ANALYTICS = "analytics",
  WORKLOADS_MANAGER = "workloads-manager",
  RESOURCES = "resources",
  ORGANIZATION = "organization",
  ACCESS = "access",
  POLICIES = "policies",
  ADMIN = "admin",
}

export const SIDE_NAV_SUBJECT_TEXTS: Record<EMenuSubjectNames, string> = {
  [EMenuSubjectNames.ANALYTICS]: "Analytics",
  [EMenuSubjectNames.WORKLOADS_MANAGER]: "Workload manager",
  [EMenuSubjectNames.RESOURCES]: "Resources",
  [EMenuSubjectNames.ORGANIZATION]: "Organization",
  [EMenuSubjectNames.ACCESS]: "Access",
  [EMenuSubjectNames.POLICIES]: "Policies",
  [EMenuSubjectNames.ADMIN]: "Admin",
};

const analytics: IMenuItem = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.ANALYTICS],
  icon: "fa-regular fa-chart-mixed",
  value: EMenuSubjectNames.ANALYTICS,
  aid: EMenuSubjectNames.ANALYTICS,
  items: [
    {
      name: DASHBOARD_ROUTE_NAMES.OVERVIEW_INDEX,
      label: "Dashboard",
      aid: "menu-item-dashboard",
      resourceType: ResourceType.DashboardsOverview,
      minPermittedActions: [Action.Read],
      subItems: [
        {
          name: DASHBOARD_ROUTE_NAMES.OVERVIEW_INDEX,
          label: "Overview",
          aid: "menu-item-overview",
          resourceType: ResourceType.DashboardsOverview,
          minPermittedActions: [Action.Read],
          hiddenForBranded: true,
        },
        {
          name: DASHBOARD_ROUTE_NAMES.RESOURCES_INDEX,
          label: "Quota management",
          aid: "menu-item-resources",
          resourceType: ResourceType.DashboardsConsumption,
          minPermittedActions: [Action.Read],
          hiddenForBranded: true,
        },
        {
          name: DASHBOARD_ROUTE_NAMES.ANALYTICS_INDEX,
          label: "Analytics",
          aid: "menu-item-analytics",
          resourceType: ResourceType.DashboardsAnalytics,
          minPermittedActions: [Action.Read],
          hiddenForBranded: true,
        },
        {
          name: DASHBOARD_ROUTE_NAMES.CONSUMPTION_INDEX,
          label: "Consumption",
          aid: "menu-item-consumption",
          resourceType: ResourceType.DashboardsConsumption,
          minPermittedActions: [Action.Read],
          featureFlagName: SettingKeys.ConsumptionReport,
          hiddenForBranded: true,
        },
        {
          name: DASHBOARD_ROUTE_NAMES.CLUSTERS_DASHBOARD_INDEX,
          label: "Multi-cluster Overview",
          aid: "menu-item-multi-cluster",
          resourceType: ResourceType.DashboardsOverview,
          minPermittedActions: [Action.Read],
          hiddenForBranded: true,
        },
      ],
    },
    {
      name: ReportRoutesName.ReportIndex,
      label: "Reports",
      aid: "menu-item-report",
      resourceType: ResourceType.Project,
      minPermittedActions: [Action.Read],
      featureToggleName: EFeatureToggle.ShowReportsGrid,
      hiddenForBranded: true,
    },
  ],
};

const workloadManager = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.WORKLOADS_MANAGER],
  icon: "fa-regular fa-laptop-code",
  value: EMenuSubjectNames.WORKLOADS_MANAGER,
  aid: EMenuSubjectNames.WORKLOADS_MANAGER,
  items: [
    {
      name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX,
      label: "Workloads",
      aid: "menu-item-workloads",
      resourceType: ResourceType.Workloads,
      minPermittedActions: [Action.Read],
    },
    {
      name: WORKLOAD_ROUTE_NAMES.WORKLOAD_DELETED_INDEX,
      label: "Deleted workloads",
      aid: "menu-item-deleted-workloads",
      resourceType: ResourceType.Workloads,
      minPermittedActions: [Action.Read],
      featureFlagName: SettingKeys.DeletedWorkloadsView,
    },
    {
      name: WORKLOAD_TEMPLATE_ROUTE_NAMES.WORKLOAD_TEMPLATE_INDEX,
      label: "Templates",
      aid: "menu-item-templates",
      resourceType: ResourceType.Templates,
      minPermittedActions: [Action.Read],
    },
    {
      name: "assets",
      label: "Assets",
      aid: "menu-item-assets",
      subItems: [
        {
          name: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
          label: "Models",
          aid: "menu-item-model-spec",
          resourceType: ResourceType.Inferences,
          minPermittedActions: [Action.Create],
          featureFlagName: SettingKeys.EnableModelCatalog,
          hiddenForBranded: true,
        },
        {
          name: ENVIRONMENT_ROUTE_NAMES.ENVIRONMENT_INDEX,
          label: "Environments",
          aid: "menu-item-environments",
          resourceType: ResourceType.Environments,
          minPermittedActions: [Action.Read],
        },
        {
          name: COMPUTE_RESOURCE_ROUTE_NAMES.COMPUTE_RESOURCE_INDEX,
          label: "Compute resources",
          aid: "menu-item-compute-resource",
          resourceType: ResourceType.ComputeResources,
          minPermittedActions: [Action.Read],
        },
        {
          name: DATA_SOURCE_ROUTE_NAMES.DATA_SOURCE_INDEX,
          label: "Data sources",
          aid: "menu-item-data-sources",
          resourceType: [
            ResourceType.NfsAssets,
            ResourceType.S3Assets,
            ResourceType.GitAssets,
            ResourceType.PvcAssets,
            ResourceType.HostPathAssets,
          ],
          minPermittedActions: [Action.Read],
        },
        {
          name: CREDENTIAL_ROUTE_NAMES.CREDENTIAL_INDEX,
          label: "Credentials",
          aid: "menu-item-credentials",
          resourceType: ResourceType.Credentials,
          minPermittedActions: [Action.Read],
        },
      ],
    },
  ],
};

const resources = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.RESOURCES],
  icon: "fa-regular fa-server",
  value: EMenuSubjectNames.RESOURCES,
  aid: EMenuSubjectNames.RESOURCES,
  items: [
    {
      name: CLUSTER_ROUTE_NAMES.CLUSTER_INDEX,
      label: "Clusters",
      aid: "menu-item-clusters",
      resourceType: ResourceType.Cluster,
      minPermittedActions: [Action.Create],
    },
    {
      name: NODE_ROUTE_NAMES.NODE_POOL_INDEX,
      label: "Nodes pools",
      aid: "menu-item-node-pools",
      resourceType: ResourceType.Nodes,
      minPermittedActions: [Action.Read],
    },
    {
      name: NODE_ROUTE_NAMES.NODE_INDEX,
      label: "Nodes",
      aid: "menu-item-nodes",
      resourceType: ResourceType.Nodes,
      minPermittedActions: [Action.Read],
    },
  ],
};

const organization = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.ORGANIZATION],
  icon: "fa-regular fa-sitemap",
  value: EMenuSubjectNames.ORGANIZATION,
  aid: EMenuSubjectNames.ORGANIZATION,
  items: [
    {
      name: PROJECT_ROUTE_NAMES.PROJECT_INDEX,
      label: "Projects",
      aid: "menu-item-projects",
      resourceType: ResourceType.Project,
      minPermittedActions: [Action.Read],
    },
    {
      name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX,
      label: "Departments",
      aid: "menu-item-departments",
      resourceType: ResourceType.Department,
      minPermittedActions: [Action.Read],
      featureFlagName: SettingKeys.DepartmentsUse,
    },
  ],
};

const access = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.ACCESS],
  icon: "fa-regular fa-user-lock",
  value: EMenuSubjectNames.ACCESS,
  aid: EMenuSubjectNames.ACCESS,
  items: [
    {
      name: ROLE_ROUTE_NAMES.ACCESS_RULES_INDEX,
      label: "Access rules",
      aid: "menu-item-access-rules",
      resourceType: ResourceType.AccessRules,
      minPermittedActions: [Action.Read],
    },
    {
      name: USER_ROUTES_NAMES.USER_INDEX,
      label: "Users",
      aid: "menu-item-users",
      resourceType: ResourceType.Users,
      minPermittedActions: [Action.Read],
    },
    {
      name: APPLICATIONS_ROUTE_NAMES.APPLICATIONS_INDEX,
      label: "Applications",
      aid: "menu-item-applications",
      resourceType: ResourceType.Apps,
      minPermittedActions: [Action.Read],
    },
    {
      name: ROLE_ROUTE_NAMES.ROLE_INDEX,
      label: "Roles",
      aid: "menu-item-roles",
      resourceType: ResourceType.Roles,
      minPermittedActions: [Action.Read],
    },
  ],
};

const policies = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.POLICIES],
  icon: "fa-regular fa-clipboard-list-check",
  value: EMenuSubjectNames.POLICIES,
  aid: EMenuSubjectNames.POLICIES,
  items: [
    {
      name: POLICIES_ROUTE_NAMES.POLICIES_INDEX,
      label: "Workload policies",
      aid: "menu-item-policies",
      resourceType: ResourceType.Policies,
      minPermittedActions: [Action.Read],
      featureFlagName: SettingKeys.EnablePolicies,
    },
  ],
};

const admin = {
  label: SIDE_NAV_SUBJECT_TEXTS[EMenuSubjectNames.ADMIN],
  icon: "fa-regular fa-gear",
  value: EMenuSubjectNames.ADMIN,
  aid: EMenuSubjectNames.ADMIN,
  items: [
    {
      name: SETTINGS_ROUTE_NAMES.SETTINGS_INDEX,
      label: "General settings",
      aid: "menu-item-settings",
      resourceType: ResourceType.Settings,
      minPermittedActions: [Action.Read],
    },
    {
      name: AUDIT_ROUTE_NAMES.AUDIT_INDEX,
      label: "Event history",
      aid: "menu-item-event-history",
      resourceType: ResourceType.EventsHistory,
      minPermittedActions: [Action.Read],
    },
  ],
};

export const menuSubjects: Record<string, IMenuItem> = {
  analytics,
  workloadManager,
  resources,
  organization,
  access,
  policies,
  admin,
};

export enum EIntervalLabels {
  /**** Infra ****/
  RefreshToken = "refresh-token",
  IdleTimer = "idle-timer",
  CheckClusterStatus = "check-cluster-status",
  FetchClusters = "fetch-clusters",
  AdminMessage = "admin-message",
  GrafanaLogin = "grafana-login",
  ClusterStatusBar = "cluster-status-bar",
  /**** Widgets ****/
  AdvancedWorkloadMetricWidgets = "advanced-workload-metric-widgets",
  CpuComputeUsagePerGpuWidget = "cpu-compute-usage-per-gpu-widget",
  CpuMemoryUsagePerGpuWidget = "cpu-memory-usage-per-gpu-widget",
  CpuMemoryUsageWidget = "cpu-memory-usage-widget",
  CpuUsageWidget = "cpu-usage-widget",
  GpuComputeUtilizationPerGpuWidget = "gpu-compute-utilization-per-gpu-widget",
  GpuComputeUtilizationWidget = "gpu-compute-utilization-widget",
  GpuMemoryUsagePerGpuWidget = "gpu-memory-usage-per-gpu-widget",
  GpuMemoryUsageWidget = "gpu-memory-usage-widget",
  LatencyWidget = "latency-widget",
  ReplicasWidget = "replicas-widget",
  ThroughputWidget = "throughput-widget",
  ReadyGpuWidget = "ready-gpu-widget",
  AllocateGpuWidget = "allocate-gpu-widget",
  ReadyNodesWidget = "ready-nodes-widget",
  PendingWorkloadsWidget = "pending-workloads-widget",
  RunningWorkloadsWidget = "running-workloads-widget",
  ResourceAllocationTimeRangeWidget = "resource-allocation-time-range-widget",
  ResourceUtilizationTimeRangeWidget = "resource-utilization-time-range-widget",
  IdleAllocatedGpuWidget = "idle-allocated-gpu-widget",
  GpuAllocationTimeRangeWidget = "gpu-allocation-time-range-widget",
  GpuUtilizationDistributionWidgetTimeRangeWidget = "gpu-utilization-distribution-widget-time-range-widget",
  WorkloadStatusWidget = "workload-status-widget",
  ResourceAllocationByWorkloadTypeWidget = "resource-allocation-by-workload-type-widget",
  /**** Indexes ****/
  IndexPostCreationRefreshRate = "index-post-creation-refresh-rate",
  NodeIndex = "node-index",
  NodePoolIndex = "node-pool-index",
  ClusterIndex = "cluster-index",
  WorkloadIndex = "workload-index",
  WorkloadIndexCount = "workload-index-count",
  DataSourcesIndex = "data-sources-index",
  CredentialsIndex = "credentials-index",
  DepartmentIndex = "department-index",
  ProjectsIndex = "projects-index",
  PolicyIndex = "policy-index",
  ReportIndex = "report-index",
  /*** Modals ***/
  PodsModal = "pods-modal",
  /**** Other ****/
  WorkloadHistoryTab = "workload-history-tab",
  WorkloadPods = "workload-pods",
  ApplyPolicySync = "apply-policy-sync",
  CreatedPvcUpdate = "created-pvc-update",
}

export enum EIntervalTime {
  /**** minutes ****/
  OneMinute = 60 * 1000,
  FiveMinutes = 5 * 60 * 1000,
  /**** seconds ****/
  ThirtySeconds = 30 * 1000,
  FiveSeconds = 5 * 1000,
  FifteenSeconds = 15 * 1000,
  FiftySeconds = 50 * 1000,
  TwoSeconds = 2 * 1000,
  TwoAndHalfSeconds = 2.5 * 1000,
}

export const intervalLabelTimeMap: { [key in EIntervalLabels]: number } = {
  [EIntervalLabels.ReadyGpuWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.AllocateGpuWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.ReadyNodesWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.PendingWorkloadsWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.RunningWorkloadsWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.ResourceAllocationTimeRangeWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.ResourceUtilizationTimeRangeWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.IdleAllocatedGpuWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.GpuAllocationTimeRangeWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.GpuUtilizationDistributionWidgetTimeRangeWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.WorkloadStatusWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.ResourceAllocationByWorkloadTypeWidget]: EIntervalTime.OneMinute,
  [EIntervalLabels.RefreshToken]: EIntervalTime.OneMinute,
  [EIntervalLabels.IdleTimer]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.CheckClusterStatus]: EIntervalTime.FifteenSeconds,
  [EIntervalLabels.FetchClusters]: EIntervalTime.OneMinute,
  [EIntervalLabels.AdminMessage]: EIntervalTime.FiveMinutes,
  [EIntervalLabels.GrafanaLogin]: EIntervalTime.OneMinute,
  [EIntervalLabels.ClusterStatusBar]: EIntervalTime.OneMinute,
  [EIntervalLabels.AdvancedWorkloadMetricWidgets]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.CpuComputeUsagePerGpuWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.CpuMemoryUsagePerGpuWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.CpuMemoryUsageWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.CpuUsageWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.GpuComputeUtilizationPerGpuWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.GpuComputeUtilizationWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.GpuMemoryUsagePerGpuWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.GpuMemoryUsageWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.LatencyWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.ReplicasWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.ThroughputWidget]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.NodeIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.NodePoolIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.ClusterIndex]: EIntervalTime.FifteenSeconds,
  [EIntervalLabels.WorkloadIndex]: EIntervalTime.FiveSeconds,
  [EIntervalLabels.WorkloadIndexCount]: EIntervalTime.FiftySeconds,
  [EIntervalLabels.WorkloadHistoryTab]: EIntervalTime.FiftySeconds,
  [EIntervalLabels.IndexPostCreationRefreshRate]: EIntervalTime.FifteenSeconds,
  [EIntervalLabels.DataSourcesIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.CredentialsIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.ProjectsIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.DepartmentIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.PodsModal]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.PolicyIndex]: EIntervalTime.ThirtySeconds,
  [EIntervalLabels.ReportIndex]: EIntervalTime.OneMinute,
  [EIntervalLabels.ApplyPolicySync]: EIntervalTime.TwoAndHalfSeconds,
  [EIntervalLabels.CreatedPvcUpdate]: EIntervalTime.TwoSeconds,
  [EIntervalLabels.WorkloadPods]: EIntervalTime.ThirtySeconds,
};

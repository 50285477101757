export enum EFeatureToggle {
  /*** General ***/
  ShowNewLayout = "release-19405-show-new-ui-navigation",
  /*** Phoenix ***/
  /*** Lemur ***/
  ShowNimInference = "release-RUN-21924-show-nim-for-inference",
  ShowServingEndpointAccessInference = "release-RUN-22019-show-serving-endpoint-inference",
  ShowPolicyForInference = "release-23112-show-policy-for-inference",
  ShowCredentialsForHFInference = "release-23112-show-credentials-for-hf-inference",
  /*** Viper ***/
  ShowUpdateAssetEnabled = "release-23268-show-asset-editing",
  ShowMigProfilesDisabled = "release-19264-show-mig-profile-deprecation",
  /*** Suricata ***/
  ShowReportsGrid = "release-20342-show-reports-grid",
  ShowOrgUnitOverTimeData = "release-19487-show-org-unit-over-time-data",
  ShowOrgUnitParity = "release-18736-show-org-unity-parity",
  ShowWorkloadDetailsTabEnabled = "release-20600-show-workload-details-tab",
  ShowNodePoolsV2 = "release-11773-show-node-pools-v2",
}

<template>
  <runai-section :title="title" :tooltip-text="titleTooltip" :tooltip-width="titleTooltipWidth" tooltip-position="right">
    <div v-if="showSearch" class="q-pl-sm q-pr-md q-pb-sm sticky-search w-25">
      <q-input v-model="searchTerm" type="search" dense placeholder="Search...">
        <template v-slot:prepend>
          <q-icon name="fa-regular fa-magnifying-glass" size="12px" />
        </template>
      </q-input>
    </div>
    <section class="runai-radio-options column items-start">
      <runai-tooltip-wrapper
        :display-tooltip="!!disableTooltip"
        :tooltip-text="disableTooltip"
        :toggle-tooltip="innerToolTipShown"
      >
        <q-option-group
          v-if="filteredOptions.length > 0"
          :class="{
            'horizontal-options': horizontal,
            row: horizontal,
            'no-left-margin': horizontal && dense,
            'scrollable-y': scrollableY,
          }"
          :options="filteredOptions"
          :model-value="modelValue"
          @update:model-value="$emit('update:model-value', $event)"
          type="radio"
          :inline="horizontal"
          :disable="disable"
          :dense="dense"
        >
          <template v-slot:label="opt">
            <div :class="{ row: !horizontal, 'items-center': !horizontal }" :aid="`radio-option-${opt.label}`">
              <div
                class="row items-center"
                :class="{ 'q-mr-md': !horizontal, ellipsis: !!optionMaxWidth }"
                :style="{ 'max-width': optionMaxWidth, height: '40px' }"
              >
                <runai-svg-icon v-if="opt.icon" class="q-mr-sm" :name="opt.icon" size="30" />
                <span class="ellipsis-label ellipsis"
                  >{{ opt.label }} <q-tooltip v-if="isTextOverflow(opt.label)">{{ opt.label }}</q-tooltip></span
                >
                <q-tooltip v-if="opt.disable && opt.disabledTooltip">{{ opt.disabledTooltip }}</q-tooltip>
                <runai-tooltip
                  v-if="opt.toolTip"
                  @revealed="innerToolTipShown = true"
                  @hidden="innerToolTipShown = false"
                  :tooltip-text="opt.toolTip"
                  tooltip-position="right"
                  :width="tooltipWidth"
                />
              </div>
            </div>
          </template>
        </q-option-group>
        <div v-else class="q-pl-sm" aid="empty-state">No results</div>
      </runai-tooltip-wrapper>
    </section>
  </runai-section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSection } from "@/components/common/runai-section";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

// Models
import type { ISelectOption } from "@/models/global.model";

const MIN_CHARS_TO_SHOW_OVERFLOW_TOOLTIP = 30;

export default defineComponent({
  name: "runai-radio-options",
  components: {
    RunaiSection,
    RunaiTooltip,
    RunaiTooltipWrapper,
    RunaiSvgIcon,
  },
  emits: ["update:model-value"],
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
    },
    options: {
      type: Array as PropType<Array<ISelectOption>>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean, null] as PropType<string | number | boolean | null>,
      required: true,
    },
    horizontal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableTooltip: {
      type: String as PropType<string>,
      required: false,
    },
    titleTooltip: {
      type: String as PropType<string>,
      required: false,
    },
    tooltipWidth: {
      type: String as PropType<string>,
      required: false,
    },
    optionMaxWidth: {
      type: String as PropType<string>,
      required: false,
    },
    titleTooltipWidth: {
      type: String as PropType<string>,
      required: false,
    },
    dense: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showSearch: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    scrollableY: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      innerToolTipShown: false,
      searchTerm: "",
    };
  },
  computed: {
    filteredOptions(): Array<ISelectOption> {
      return this.options.filter((option) => option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
  methods: {
    isTextOverflow(text: string): boolean {
      return text.length > MIN_CHARS_TO_SHOW_OVERFLOW_TOOLTIP;
    },
  },
});
</script>
<style lang="scss" scoped>
.runai-radio-options {
  .scrollable-y {
    max-height: 35vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .horizontal-options {
    margin-left: -18px;
    gap: 40px;

    &.no-left-margin {
      margin-left: -8px;
    }
  }
}
.sticky-search {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}
</style>

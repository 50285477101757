export enum errorMessages {
  ENTER_A_VALUE = "Enter a value",
  SELECT_A_VALUE = "Select a value",
  ENTER_A_PORT = "Enter port",
  SELECT_A_MODE = "Select a mode",
  SELECT_NODE_TYPE = "Select node type",
  VALUE_ABOVE_ZERO = "Enter a value above 0",
  NUMBER_GREATER_THAN_ZERO = "Enter a number above 0",
  DURATION_GREATER_THAN_ZERO = "Enter a limit higher than 0 minutes",
  VALID_FORMAT = "Enter a name starting with a lowercase letter and including only a-z, 0-9, -",
  VALID_APP_FORMAT = "Enter a name starting with a lowercase letter and including only a-z, 0-9, -, _",
  VALID_FORMAT_KEY = "Enter a key including only a-z, 0-9, -",
  VALID_TOPOLOGY_KEY = "Enter a key including only a-z, 0-9, and the following symbols: - _ . / It must start and end with a-z or 0-9 and can only include one /",
  VALID_FORMAT_VALUE = "Enter a value including only a-z, 0-9, -",
  VALID_NAMESPACE = "Enter a namespace starting with a lowercase letter and including only a-z, 0-9, -",
  NAME_NOT_EMPTY = "Enter a name",
  PATH_NOT_EMPTY = "Enter a path",
  CLAIM_NOT_EMPTY = "Select a claim",
  KEY_NOT_EMPTY = "Enter a key",
  VALUE_NOT_EMPTY = "Enter a value",
  ENTER_A_TOKEN = "Enter a token",
  STORAGE_CLASS_NOT_EMPTY = "Select a storage class",
  URL_NOT_EMPTY = "Enter a URL",
  SELECT_TYPE = "Select a type",
  SELECT_DEPARTMENT = "Select a department",
  SELECT_TEMPLATE = "Select a template or start from scratch",
  SELECT_ENVIRONMENT = "Select an environment",
  SELECT_DISTRIBUTION = "Select a distribution",
  SELECT_DATA_SOURCE = "Select a data source",
  SELECT_COMPUTE = "Select a resource",
  SELECT_SOURCE = "Select a resource",
  SELECT_VERSION = "Select a version",
  INVALID_DIRECTORY_PATH = "Enter a valid path",
  INVALID_CONTAINER_PATH = "Invalid container path",
  INVALID_URL = "Enter a valid URL",
  UNIQUE_NAME = "This name already exists",
  UNIQUE_LABELS = "This value already exists for this key",
  NAME_ALREADY_EXIST = "This name already exists",
  SERVER_NAME_OR_IP_EMPTY = "Enter a name or IP",
  SELECT_CREDENTIALS = "Select credentials",
  USERNAME_NOT_EMPTY = "Enter a username",
  PASSWORD_NOT_EMPTY = "Enter a password",
  INVALID_PASSWORD_FORMAT = "Please use lower case (a-z), upper case (A-Z), numbers (0-9), and special characters (!@#$%^&*). The password must be at least 8 characters long.",
  PASSWORD_DOES_NOT_MATCH = "Passwords do not match",
  SECRET_NOT_EMPTY = "Enter a secret",
  KEY_REGEXP_VALIDATION = "Key must fit the following regular expression",
  VALUE_REGEXP_VALIDATION = "Value must fit the following regular expression",
  ENETER_RESOURCE = "Enter a resource",
  ENTER_QUANTITY = "Enter a quantity",
  RESOURCE_ALREADY_USED = "This resource already exists",
  ENTENDED_RESOURCE_NAME_NOT_VALID = "Enter a valid resource",
  INVALID_QUANTITY = "Invalid quantity",
  SUPPLEMENTAL_GROUPS_NOT_VALID = "Supplementary groups must be a comma-separated list of numbers",
  LIMIT_EQUAL_OR_HIGHER_THAN_REQUEST = "Enter a limit equal to or higher than the request",
  ENTER_A_LIMIT_ABOVE_ZERO = "Enter a limit above 0",
  FORM_INCOMPLETE = "Please review and fix the issues in the form",
  EMAIL_NOT_EMPTY = "Enter an email",
  EMAIL_NOT_VALID = "Enter a valid email address",
  EMAIL_ALREADY_EXIST = "This email already exists",
  EMAIL_OR_NAME_ALREADY_EXIST = "This email or name already exists",
  INVALID_EMAIL_OR_NAME = "Enter an email or name including only A-z, 0-9, @, -, _, .",
  SELECT_A_FRAMEWORK = "Select a framework",
  SELECT_A_TYPE = "Select at least one type",
  SELECT_A_NAME = "Select a name",
  SELECT_MODEL = "Select a model",
  ENTER_A_VALID_TOKEN = "Enter a valid token",
  ENTER_A_VALID_NAME = "Enter a valid name",
  ENTER_A_NUMBER_EQUAL_TO_OR_LOWER_THAN = "Enter a number equal to or lower than",
  ENTER_A_NUMBER_FROM_MIN_TO_MAX = "Enter a number from ${1} to ${2}",
  ENTER_A_VLLM_BASED_MODEL = "Enter a model supported by vLLM version ${1}",
  TOKEN_IS_NOT_AUTHORIZED = "This token isn't authorized. Request access via Hugging Face.",
  AT_LEAST_ONE_TARGET_LOCATION_ISSUE = "There are issues with at least one target location",
  CLUSTER_NOT_CONNECTED = "The selected cluster isn't connected. Try selecting another one.",
  KNATIVE_IS_NOT_INSTALLED_SELECTED_CLUSTER = "Knative isn't installed on the selected cluster. Contact your administrator.",
  REQUIRED_FIELD = "This field is required based on the policy your administrator set",
  ENTER_A_VALUE_BETWEEN = "Enter a value between ${min} and ${max}",
  ENTER_A_VALUE_BETWEEN_WITH_STEP = "Enter a value that follows steps of ${step}, between ${min}-${max}",
  VALUE_MUST_FOLLOW_STEPS_STARTING_MIN = "Enter a value that follows steps of ${step}, starting ${min}",
  VALUE_MUST_FOLLOW_STEPS_OF = "Enter a value that follows steps of ${step}",
  ENTER_A_VALUE_ABOVE_MIN = "Enter a value equal or above ${min}",
  ENTER_A_VALUE_BELOW_MAX = "Enter a value equal or below ${max}",
  CANT_BE_MODIFIED_POLICY = "This can't be modified based on the policy your administrator set.",
  CANT_BE_SELECTED_POLICY = "This can't be selected based on the policy your administrator set.",
  TIMEFRAME_BETWEEN_MIN_AND_MAX_SECONDS = "Enter a timeframe between ${min} and ${max} seconds",
  TIMEFRAME_STEPS = "Enter a timeframe that follows steps of ${step}, starting from ${min}, ${min + step}, etc.",
  INVALID_PARALLELISM = "Enter a value that is less than or equal to the number of runs",
  PROFILE_NAME_OR_HASH_NOT_EMPTY = "Enter a profile name or hash",
  ENTER_A_VALID_PROFILE_NAME_OR_HASH = "Enter a name or hash including only a-z, 0-9, -, _",
  ENTER_A_VALID_NIM_API_KEY = "Enter an API key including only A-Z, a-z, 0-9, -, _",

  //Projects
  PERMISSIONS_SELECT_NOT_EMPTY = "Set at least one user with access to the project",
  SELECT_PROJECT = "Select a project",
  // Access rules
  SUBJECT_NOT_EMPTY = "Select a subject",
  ROLE_TYPE_NOT_EMPTY = "Select a role",
  SCOPE_TYPE_NOT_EMPTY = "Select a scope",
  SUBJECT_ID_NOT_EMPTY = "Select or type",
  VALID_TIME_FRAME = "Enter a timeframe of at least 0 seconds",
  UNSAVED_RULE = "Unsaved rule",
  ADD_VALID_POLICY_YAML = "Add a valid policy YAML",
  EQUAL_TO_OR_HIGHER_THAN_THE_MINIMUM = "Enter a number equal to or higher than the minimum",
  //Cluster
  SELECT_CLUSTER = "Select a cluster",
}

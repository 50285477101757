import { httpService } from "@/services/infra/https.service/http.service";
import { identityManagerApi } from "@/services/infra/client-apis/identity-manager-api/identity-manager-api";
import type {
  UserApp,
  UserAppCreationRequest,
  UserAppPostResponse,
  RegenerateUserApplicationSecret200Response,
} from "@/swagger-models/identity-manager-client";

export const personalApplicationsService = {
  getPersonalApps,
  getPersonalApp,
  createPersonalApp,
  removePersonalApp,
  generatePersonalAppSecret,
};

async function getPersonalApps(): Promise<UserApp[]> {
  try {
    const response = await identityManagerApi.userApplicationsApi.getUserApplications();
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getPersonalApp(appId: string): Promise<UserApp> {
  try {
    const response = await identityManagerApi.userApplicationsApi.getUserApplicationById(appId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createPersonalApp(application: UserAppCreationRequest): Promise<UserAppPostResponse> {
  try {
    const response = await identityManagerApi.userApplicationsApi.createUserApplication(application);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removePersonalApp(appId: string): Promise<void> {
  try {
    await identityManagerApi.userApplicationsApi.deleteUserApplicationById(appId);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function generatePersonalAppSecret(appId: string): Promise<RegenerateUserApplicationSecret200Response> {
  try {
    const response = await identityManagerApi.userApplicationsApi.regenerateUserApplicationSecret(appId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

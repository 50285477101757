/* tslint:disable */
/* eslint-disable */
/**
 * Notifications Service Config API
 * API for notifications-service config endpoint
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const EmailAuthType = {
    Login: 'auth_login',
    Plain: 'auth_plain'
} as const;

export type EmailAuthType = typeof EmailAuthType[keyof typeof EmailAuthType];


/**
 * 
 * @export
 * @interface EmailNotificationChannelConfig
 */
export interface EmailNotificationChannelConfig {
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationChannelConfig
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationChannelConfig
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationChannelConfig
     */
    'smtpHost'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationChannelConfig
     */
    'from'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailNotificationChannelConfig
     */
    'smtpPort'?: number;
    /**
     * 
     * @type {EmailAuthType}
     * @memberof EmailNotificationChannelConfig
     */
    'authType'?: EmailAuthType;
    /**
     * 
     * @type {boolean}
     * @memberof EmailNotificationChannelConfig
     */
    'smtpTlsEnabled'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailNotificationChannelConfig
     */
    'recipients'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationChannelConfig
     */
    'fromDisplayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailNotificationChannelConfig
     */
    'directNotifications'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof EmailNotificationChannelConfig
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface NotificationChannel
 */
export interface NotificationChannel {
    /**
     * 
     * @type {NotificationChannelType}
     * @memberof NotificationChannel
     */
    'type': NotificationChannelType;
    /**
     * 
     * @type {NotificationChannelForPatchConfig}
     * @memberof NotificationChannel
     */
    'config': NotificationChannelForPatchConfig;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NotificationChannelAllOf
 */
export interface NotificationChannelAllOf {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelAllOf
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NotificationChannelConfig
 */
export interface NotificationChannelConfig {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelConfig
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelConfig
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelConfig
     */
    'smtpHost'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelConfig
     */
    'from'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelConfig
     */
    'smtpPort'?: number;
    /**
     * 
     * @type {EmailAuthType}
     * @memberof NotificationChannelConfig
     */
    'authType'?: EmailAuthType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationChannelConfig
     */
    'smtpTlsEnabled'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationChannelConfig
     */
    'recipients'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelConfig
     */
    'fromDisplayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationChannelConfig
     */
    'directNotifications'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelConfig
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @interface NotificationChannelForPatch
 */
export interface NotificationChannelForPatch {
    /**
     * 
     * @type {NotificationChannelType}
     * @memberof NotificationChannelForPatch
     */
    'type'?: NotificationChannelType;
    /**
     * 
     * @type {NotificationChannelForPatchConfig}
     * @memberof NotificationChannelForPatch
     */
    'config'?: NotificationChannelForPatchConfig;
}
/**
 * 
 * @export
 * @interface NotificationChannelForPatchConfig
 */
export interface NotificationChannelForPatchConfig {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelForPatchConfig
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelForPatchConfig
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelForPatchConfig
     */
    'smtpHost'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelForPatchConfig
     */
    'from'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelForPatchConfig
     */
    'smtpPort'?: number;
    /**
     * 
     * @type {EmailAuthType}
     * @memberof NotificationChannelForPatchConfig
     */
    'authType'?: EmailAuthType;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationChannelForPatchConfig
     */
    'smtpTlsEnabled'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationChannelForPatchConfig
     */
    'recipients'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelForPatchConfig
     */
    'fromDisplayName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationChannelForPatchConfig
     */
    'directNotifications'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelForPatchConfig
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @interface NotificationChannelForValidate
 */
export interface NotificationChannelForValidate {
    /**
     * 
     * @type {NotificationChannelType}
     * @memberof NotificationChannelForValidate
     */
    'type': NotificationChannelType;
    /**
     * 
     * @type {NotificationChannelForPatchConfig}
     * @memberof NotificationChannelForValidate
     */
    'config': NotificationChannelForPatchConfig;
}
/**
 * 
 * @export
 * @interface NotificationChannelMeta
 */
export interface NotificationChannelMeta {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelMeta
     */
    'name': string;
    /**
     * 
     * @type {NotificationChannelType}
     * @memberof NotificationChannelMeta
     */
    'type': NotificationChannelType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationChannelType = {
    Email: 'email'
} as const;

export type NotificationChannelType = typeof NotificationChannelType[keyof typeof NotificationChannelType];


/**
 * 
 * @export
 * @interface NotificationState
 */
export interface NotificationState {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationState
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationType
 */
export interface NotificationType {
    /**
     * 
     * @type {string}
     * @memberof NotificationType
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationType
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationType
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {Array<SubscriptionEvent>}
     * @memberof Subscription
     */
    'events': Array<SubscriptionEvent>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Subscription
     */
    'channels': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionAllOf
 */
export interface SubscriptionAllOf {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionAllOf
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionEvent
 */
export interface SubscriptionEvent {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionEvent
     */
    'category': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionEvent
     */
    'types': Array<string>;
}
/**
 * 
 * @export
 * @interface SubscriptionForPut
 */
export interface SubscriptionForPut {
    /**
     * 
     * @type {Array<SubscriptionEvent>}
     * @memberof SubscriptionForPut
     */
    'events': Array<SubscriptionEvent>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionForPut
     */
    'channels': Array<string>;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationChannelsApi - axios parameter creator
 * @export
 */
export const NotificationChannelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create configuration of notification channel
         * @summary Create configuration of notification channel
         * @param {NotificationChannel} [notificationChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationChannelConfig: async (notificationChannel?: NotificationChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete configuration of Notification Channel
         * @summary Delete configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationChannelConfig: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteNotificationChannelConfig', 'name', name)
            const localVarPath = `/api/v1/notification-channels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get configuration of notification channel
         * @summary Get configuration of notification channel
         * @param {string} name Notification Channel name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationChannelConfig: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getNotificationChannelConfig', 'name', name)
            const localVarPath = `/api/v1/notification-channels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get supported Notification Channels
         * @summary Get supported Notification Channels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationChannels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch configuration of Notification Channel
         * @summary Patch configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {NotificationChannelForPatch} [notificationChannelForPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationChannelConfig: async (name: string, notificationChannelForPatch?: NotificationChannelForPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('patchNotificationChannelConfig', 'name', name)
            const localVarPath = `/api/v1/notification-channels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannelForPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update configuration of Notification Channel
         * @summary Update configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {NotificationChannel} [notificationChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationChannelConfig: async (name: string, notificationChannel?: NotificationChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateNotificationChannelConfig', 'name', name)
            const localVarPath = `/api/v1/notification-channels/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate configuration of Notification Channel
         * @summary Validate configuration of Notification Channel
         * @param {NotificationChannelForValidate} [notificationChannelForValidate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotificationChannel: async (notificationChannelForValidate?: NotificationChannelForValidate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/validate-notification-channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationChannelForValidate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationChannelsApi - functional programming interface
 * @export
 */
export const NotificationChannelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationChannelsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create configuration of notification channel
         * @summary Create configuration of notification channel
         * @param {NotificationChannel} [notificationChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotificationChannelConfig(notificationChannel?: NotificationChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotificationChannelConfig(notificationChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete configuration of Notification Channel
         * @summary Delete configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotificationChannelConfig(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotificationChannelConfig(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get configuration of notification channel
         * @summary Get configuration of notification channel
         * @param {string} name Notification Channel name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationChannelConfig(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationChannel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationChannelConfig(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get supported Notification Channels
         * @summary Get supported Notification Channels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationChannels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationChannelMeta>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationChannels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch configuration of Notification Channel
         * @summary Patch configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {NotificationChannelForPatch} [notificationChannelForPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNotificationChannelConfig(name: string, notificationChannelForPatch?: NotificationChannelForPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNotificationChannelConfig(name, notificationChannelForPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update configuration of Notification Channel
         * @summary Update configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {NotificationChannel} [notificationChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationChannelConfig(name: string, notificationChannel?: NotificationChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationChannelConfig(name, notificationChannel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate configuration of Notification Channel
         * @summary Validate configuration of Notification Channel
         * @param {NotificationChannelForValidate} [notificationChannelForValidate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateNotificationChannel(notificationChannelForValidate?: NotificationChannelForValidate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateNotificationChannel(notificationChannelForValidate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationChannelsApi - factory interface
 * @export
 */
export const NotificationChannelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationChannelsApiFp(configuration)
    return {
        /**
         * Create configuration of notification channel
         * @summary Create configuration of notification channel
         * @param {NotificationChannel} [notificationChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotificationChannelConfig(notificationChannel?: NotificationChannel, options?: any): AxiosPromise<void> {
            return localVarFp.createNotificationChannelConfig(notificationChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete configuration of Notification Channel
         * @summary Delete configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationChannelConfig(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotificationChannelConfig(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get configuration of notification channel
         * @summary Get configuration of notification channel
         * @param {string} name Notification Channel name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationChannelConfig(name: string, options?: any): AxiosPromise<NotificationChannel> {
            return localVarFp.getNotificationChannelConfig(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get supported Notification Channels
         * @summary Get supported Notification Channels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationChannels(options?: any): AxiosPromise<Array<NotificationChannelMeta>> {
            return localVarFp.getNotificationChannels(options).then((request) => request(axios, basePath));
        },
        /**
         * Patch configuration of Notification Channel
         * @summary Patch configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {NotificationChannelForPatch} [notificationChannelForPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotificationChannelConfig(name: string, notificationChannelForPatch?: NotificationChannelForPatch, options?: any): AxiosPromise<void> {
            return localVarFp.patchNotificationChannelConfig(name, notificationChannelForPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Update configuration of Notification Channel
         * @summary Update configuration of Notification Channel
         * @param {string} name Notification Channel name
         * @param {NotificationChannel} [notificationChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationChannelConfig(name: string, notificationChannel?: NotificationChannel, options?: any): AxiosPromise<void> {
            return localVarFp.updateNotificationChannelConfig(name, notificationChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate configuration of Notification Channel
         * @summary Validate configuration of Notification Channel
         * @param {NotificationChannelForValidate} [notificationChannelForValidate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateNotificationChannel(notificationChannelForValidate?: NotificationChannelForValidate, options?: any): AxiosPromise<boolean> {
            return localVarFp.validateNotificationChannel(notificationChannelForValidate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationChannelsApi - object-oriented interface
 * @export
 * @class NotificationChannelsApi
 * @extends {BaseAPI}
 */
export class NotificationChannelsApi extends BaseAPI {
    /**
     * Create configuration of notification channel
     * @summary Create configuration of notification channel
     * @param {NotificationChannel} [notificationChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public createNotificationChannelConfig(notificationChannel?: NotificationChannel, options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).createNotificationChannelConfig(notificationChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete configuration of Notification Channel
     * @summary Delete configuration of Notification Channel
     * @param {string} name Notification Channel name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public deleteNotificationChannelConfig(name: string, options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).deleteNotificationChannelConfig(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get configuration of notification channel
     * @summary Get configuration of notification channel
     * @param {string} name Notification Channel name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public getNotificationChannelConfig(name: string, options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).getNotificationChannelConfig(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get supported Notification Channels
     * @summary Get supported Notification Channels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public getNotificationChannels(options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).getNotificationChannels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch configuration of Notification Channel
     * @summary Patch configuration of Notification Channel
     * @param {string} name Notification Channel name
     * @param {NotificationChannelForPatch} [notificationChannelForPatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public patchNotificationChannelConfig(name: string, notificationChannelForPatch?: NotificationChannelForPatch, options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).patchNotificationChannelConfig(name, notificationChannelForPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update configuration of Notification Channel
     * @summary Update configuration of Notification Channel
     * @param {string} name Notification Channel name
     * @param {NotificationChannel} [notificationChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public updateNotificationChannelConfig(name: string, notificationChannel?: NotificationChannel, options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).updateNotificationChannelConfig(name, notificationChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate configuration of Notification Channel
     * @summary Validate configuration of Notification Channel
     * @param {NotificationChannelForValidate} [notificationChannelForValidate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationChannelsApi
     */
    public validateNotificationChannel(notificationChannelForValidate?: NotificationChannelForValidate, options?: AxiosRequestConfig) {
        return NotificationChannelsApiFp(this.configuration).validateNotificationChannel(notificationChannelForValidate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationStateApi - axios parameter creator
 * @export
 */
export const NotificationStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get notification state
         * @summary Get notification state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationState: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tenant notifications state
         * @summary Update notification state
         * @param {NotificationState} [notificationState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationState: async (notificationState?: NotificationState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationStateApi - functional programming interface
 * @export
 */
export const NotificationStateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationStateApiAxiosParamCreator(configuration)
    return {
        /**
         * Get notification state
         * @summary Get notification state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationState(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationState(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update tenant notifications state
         * @summary Update notification state
         * @param {NotificationState} [notificationState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationState(notificationState?: NotificationState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationState(notificationState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationStateApi - factory interface
 * @export
 */
export const NotificationStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationStateApiFp(configuration)
    return {
        /**
         * Get notification state
         * @summary Get notification state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationState(options?: any): AxiosPromise<NotificationState> {
            return localVarFp.getNotificationState(options).then((request) => request(axios, basePath));
        },
        /**
         * Update tenant notifications state
         * @summary Update notification state
         * @param {NotificationState} [notificationState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationState(notificationState?: NotificationState, options?: any): AxiosPromise<NotificationState> {
            return localVarFp.updateNotificationState(notificationState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationStateApi - object-oriented interface
 * @export
 * @class NotificationStateApi
 * @extends {BaseAPI}
 */
export class NotificationStateApi extends BaseAPI {
    /**
     * Get notification state
     * @summary Get notification state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationStateApi
     */
    public getNotificationState(options?: AxiosRequestConfig) {
        return NotificationStateApiFp(this.configuration).getNotificationState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update tenant notifications state
     * @summary Update notification state
     * @param {NotificationState} [notificationState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationStateApi
     */
    public updateNotificationState(notificationState?: NotificationState, options?: AxiosRequestConfig) {
        return NotificationStateApiFp(this.configuration).updateNotificationState(notificationState, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationTypesApi - axios parameter creator
 * @export
 */
export const NotificationTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get list of available notification types
         * @summary Get notification types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationTypesApi - functional programming interface
 * @export
 */
export const NotificationTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get list of available notification types
         * @summary Get notification types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationTypesApi - factory interface
 * @export
 */
export const NotificationTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationTypesApiFp(configuration)
    return {
        /**
         * Get list of available notification types
         * @summary Get notification types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationTypes(options?: any): AxiosPromise<Array<NotificationType>> {
            return localVarFp.getNotificationTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationTypesApi - object-oriented interface
 * @export
 * @class NotificationTypesApi
 * @extends {BaseAPI}
 */
export class NotificationTypesApi extends BaseAPI {
    /**
     * Get list of available notification types
     * @summary Get notification types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationTypesApi
     */
    public getNotificationTypes(options?: AxiosRequestConfig) {
        return NotificationTypesApiFp(this.configuration).getNotificationTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create user subscription
         * @summary Create user subscription
         * @param {Subscription} [subscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (subscription?: Subscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user subscription
         * @summary Delete user subscription
         * @param {string} id Subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSubscription', 'id', id)
            const localVarPath = `/api/v1/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current users subscriptions
         * @summary Get current users subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubscriptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user subscription
         * @summary Update user subscription
         * @param {string} id Subscription id
         * @param {SubscriptionForPut} [subscriptionForPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (id: string, subscriptionForPut?: SubscriptionForPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubscription', 'id', id)
            const localVarPath = `/api/v1/subscriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionForPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create user subscription
         * @summary Create user subscription
         * @param {Subscription} [subscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(subscription?: Subscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(subscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user subscription
         * @summary Delete user subscription
         * @param {string} id Subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscription(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubscription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current users subscriptions
         * @summary Get current users subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMySubscriptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subscription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMySubscriptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user subscription
         * @summary Update user subscription
         * @param {string} id Subscription id
         * @param {SubscriptionForPut} [subscriptionForPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(id: string, subscriptionForPut?: SubscriptionForPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(id, subscriptionForPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * Create user subscription
         * @summary Create user subscription
         * @param {Subscription} [subscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(subscription?: Subscription, options?: any): AxiosPromise<Subscription> {
            return localVarFp.createSubscription(subscription, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user subscription
         * @summary Delete user subscription
         * @param {string} id Subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscription(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSubscription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current users subscriptions
         * @summary Get current users subscriptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubscriptions(options?: any): AxiosPromise<Array<Subscription>> {
            return localVarFp.getMySubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user subscription
         * @summary Update user subscription
         * @param {string} id Subscription id
         * @param {SubscriptionForPut} [subscriptionForPut] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(id: string, subscriptionForPut?: SubscriptionForPut, options?: any): AxiosPromise<Subscription> {
            return localVarFp.updateSubscription(id, subscriptionForPut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * Create user subscription
     * @summary Create user subscription
     * @param {Subscription} [subscription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public createSubscription(subscription?: Subscription, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).createSubscription(subscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user subscription
     * @summary Delete user subscription
     * @param {string} id Subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public deleteSubscription(id: string, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).deleteSubscription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current users subscriptions
     * @summary Get current users subscriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getMySubscriptions(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getMySubscriptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user subscription
     * @summary Update user subscription
     * @param {string} id Subscription id
     * @param {SubscriptionForPut} [subscriptionForPut] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public updateSubscription(id: string, subscriptionForPut?: SubscriptionForPut, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).updateSubscription(id, subscriptionForPut, options).then((request) => request(this.axios, this.basePath));
    }
}



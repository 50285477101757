<template>
  <runai-expansion-item
    label="Cluster"
    :default-opened="!readOnly"
    :hide-expend-icon="readOnly"
    :disable-opening="readOnly"
    aid="runai-cluster-section"
    :section-invalid="sectionInvalid"
  >
    <template #subheader>
      <span aid="cluster-section-subheader">{{ summary }}</span>
    </template>
    <div class="q-mb-md">
      <div class="q-py-md">Set under which cluster to create this {{ entity }}</div>
      <div class="row">
        <runai-select
          aid="cluster-select"
          class="cluster-select col-8"
          :model-value="selectedCluster"
          :options="clusterOptions"
          @update:model-value="updateSelectedCluster"
          placeholder="Select a cluster"
          emit-value
          unclearable
          :rules="[isSelected]"
        />
      </div>
    </div>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { isNotEmpty } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import { RunaiSelect } from "../runai-select";
import { useClusterStore } from "@/stores/cluster.store";
import type { ISelectOption } from "@/models/global.model";
// Constants

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiSelect,
  },
  emits: ["is-section-invalid", "changed-cluster"],
  props: {
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    entity: {
      type: String as PropType<string>,
      required: true,
    },
    selectedCluster: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
    };
  },
  created() {
    this.clusterStore.setSelectedClusterById(this.selectedCluster);
  },
  computed: {
    summary(): string {
      return this.clusterStore.clusterList.find((cluster) => cluster.uuid === this.selectedCluster)?.name || "None";
    },
    sectionInvalid(): boolean {
      return !this.selectedCluster;
    },
    clusterOptions(): ISelectOption[] {
      return this.clusterStore.clusterList.map((cluster) => {
        return {
          label: cluster.name,
          value: cluster.uuid,
        };
      });
    },
  },
  methods: {
    updateSelectedCluster(clusterUuid: string): void {
      //Viper:TODO - we need to remove this line and setSelectedClusterById only upon form submit - RUN-24647
      this.clusterStore.setSelectedClusterById(clusterUuid);
      this.$emit("changed-cluster", clusterUuid);
    },
    isSelected(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.SELECT_CLUSTER;
    },
  },
});
</script>
<style lang="scss" scoped></style>

<template>
  <section class="dresources-index">
    <div class="dashboard-page-filter q-px-md">
      <div class="filters-container row q-mr-auto">
        <runai-page-filter
          :filter-options="filterOptions"
          :initial-filters-model="filterBy"
          @update-column-filters="onFilterChange"
          use-selection
          :async-options-promise-map="asyncOptionsPromiseMap"
          :filter-by="clusterFilterBy"
          hide-all-cluster-filter-option
        />
      </div>
    </div>
    <div v-if="dataReady" class="q-pa-sm full-width">
      <div class="row">
        <div class="col-4 q-px-xs">
          <single-stat-chart
            :numerator="quota"
            :denominator="total"
            :value-color="CHART_COLOR_PALETTE.ROYAL_PURPLE"
            label="Quota / Total"
            show-percentage
            unit="GPU"
            tooltip-text="Quota: The number of GPUs allocated to the subordinate departments or projects
</br>Total: The number of GPUs in the cluster"
          ></single-stat-chart>
        </div>
        <div class="col-4 q-px-xs">
          <single-stat-chart
            :numerator="allocated"
            :denominator="quota"
            :value-color="CHART_COLOR_PALETTE.ROYAL_PURPLE"
            label="Allocated / Quota"
            show-percentage
            unit="GPU"
            :color-configuration-array="[
              { from: 0, to: 50, color: SINGLE_STAT_COLOR_PALETTE.RED },
              { from: 50, to: 90, color: SINGLE_STAT_COLOR_PALETTE.YELLOW },
              { from: 90, to: 1000, color: SINGLE_STAT_COLOR_PALETTE.GREEN },
            ]"
            tooltip-text="Allocated: The number of GPUs used by the workloads<br>Quota: The number of GPUs allocated to the subordinate departments or projects"
          ></single-stat-chart>
        </div>
        <div class="col-4 q-px-xs">
          <single-stat-chart label="Pending workloads" :single-value="pendingWorkloads"></single-stat-chart>
        </div>
      </div>
      <div class="row q-pt-sm q-pb-xs">
        <div class="col-4 q-px-xs">
          <graph-chart
            :data="quotaByNodePool"
            :drilldown-data="quotaByNodePoolByDepartments"
            :title="`Quota ${byNodePoolTitle}`"
            tooltip-sum-title="Total GPU quota"
            y-axis-title="GPU"
            tooltip-value-title="GPU quota"
            :type="EChartType.STACKED_COLUMN"
            :drilldown-titles="DRILL_DOWN_TITLES"
            :show-legend="!isOnlyDefaultNodePool && !hideNodePoolsFilter"
          ></graph-chart>
        </div>
        <div class="col-4 q-px-xs">
          <graph-chart
            :data="allocationByNodePool"
            :drilldown-data="allocationByNodePoolByDepartments"
            :title="`Allocation ${byNodePoolTitle}`"
            y-axis-title="GPU"
            tooltip-sum-title="Total GPU allocation"
            tooltip-value-title="GPU allocation"
            :type="EChartType.STACKED_COLUMN"
            :drilldown-titles="DRILL_DOWN_TITLES"
            :show-legend="!isOnlyDefaultNodePool && !hideNodePoolsFilter"
          ></graph-chart>
        </div>
        <div class="col-4 q-px-xs">
          <graph-chart
            :data="pendingsByNodePool"
            :drilldown-data="pendingsByNodePoolByDepartments"
            :title="`Pending workloads ${byNodePoolTitle}`"
            y-axis-title=""
            tooltip-sum-title="Pending workloads"
            tooltip-value-title="Total pending workloads"
            :type="EChartType.STACKED_COLUMN"
            :drilldown-titles="DRILL_DOWN_TITLES"
            :show-legend="!isOnlyDefaultNodePool && !hideNodePoolsFilter"
          ></graph-chart>
        </div>
      </div>
      <div class="row q-pt-xs q-pb-xs">
        <div class="col-6 q-px-xs">
          <graph-chart
            v-if="isDepartmentEnabled"
            :data="lowestRatioAllocationDepartmentsArray"
            :drilldown-data="lowestRatioAllocationProjectsArrayByDepartments"
            :title="`Departments with lowest allocation ${byNodePoolTitle}`"
            y-axis-title="Ratio"
            tooltip-sum-title="GPU allocation"
            tooltip-value-title="Ratio"
            tool-tip-value-postfix="%"
            tooltip-extra-sum-title="GPU quota"
            :type="EChartType.MULTI_BAR"
            :drilldown-titles="DRILL_DOWN_TITLES_DEPARTMENTS"
            :show-legend="!isOnlyDefaultNodePool && !hideNodePoolsFilter"
          ></graph-chart>
        </div>
        <div class="q-px-xs" :class="[isDepartmentEnabled ? 'col-6' : 'col-12']">
          <graph-chart
            :data="lowestRatioAllocationProjectsArray"
            :title="`Projects with lowest allocation ratio ${byNodePoolTitle}`"
            y-axis-title="Ratio"
            tooltip-sum-title="GPU allocation"
            tooltip-value-title="Ratio"
            tool-tip-value-postfix="%"
            tooltip-extra-sum-title="GPU quota"
            :type="EChartType.MULTI_BAR"
            :show-legend="!isOnlyDefaultNodePool && !hideNodePoolsFilter"
          ></graph-chart>
        </div>
      </div>
      <div class="row q-py-xs">
        <div class="col-12 q-px-xs">
          <columns-and-line-chart
            :categories="categories"
            title="Over time allocation / quota"
            y-axis-title="GPU"
            :column-series="columnSeries"
            :spline-series="splineSeries"
            @date-range-changed="onDateRangeChanged"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import GraphChart from "@/components/dashboard/graph-chart/graph-chart.vue";
import SingleStatChart from "@/components/dashboard/single-stat-chart/single-stat-chart.vue";
import ColumnsAndLineChart from "@/components/dashboard/columns-and-line-chart/columns-and-line-chart.vue";
import { RunaiPageFilter } from "@/components/common/runai-page-filter";

// Services
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { dateUtil, TimeUnit } from "@/utils/date.util";
// stores
import { useClusterStore } from "@/stores/cluster.store";
import { useMetricsStore } from "@/stores/metrics.store";
import { useAuthStore } from "@/stores/auth.store";
import { useSettingStore } from "@/stores/setting.store";
import { useAppStore } from "@/stores/app.store";

// models
import type { INodePool } from "@/models/node-pool.model";
import type { DateRange, IChartArrayItemConfig, MetricsFilter } from "@/models/metrics.model";
import { type IFilterBy, type IFilterModel, type IFilterOption } from "@/models/filter.model";
import { EFilterOption, filterOptions } from "@/views/dashboard/resources//resources.model";
import type { IColumnChartSeries } from "@/models/chart.model";
import { CHART_COLOR_PALETTE, EChartType, SINGLE_STAT_COLOR_PALETTE } from "@/models/chart.model";
import { DRILL_DOWN_TITLES, DRILL_DOWN_TITLES_DEPARTMENTS, DRILL_DOWN_TITLES_PROJECTS } from "@/models/metrics.model";
import type { ISelectOption } from "@/models/global.model";
import { useNodePoolStore } from "@/stores/node-pool.store";
import { storageUtil } from "@/utils/storage.util";
import { QUOTA_MANAGEMENT_FILTERS } from "@/common/storage.constant";
import { alertUtil } from "@/utils/alert.util";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { filterUtil } from "@/utils/filter.util/filter.util";
import {
  type Department,
  DepartmentFilterSortFields,
  type Project,
  ProjectFilterSortFields,
} from "@/swagger-models/org-unit-service-client";

export default defineComponent({
  components: { RunaiPageFilter, ColumnsAndLineChart, SingleStatChart, GraphChart },
  data() {
    return {
      asyncOptionsPromiseMap: {
        project: this.getFilteredProjects,
        department: this.getFilteredDepartments,
        nodepool: this.getFilteredNodePools,
      } as unknown as Record<string, (searchQuery: string) => Promise<Array<ISelectOption>>>,

      // stores
      appStore: useAppStore(),
      settingsStore: useSettingStore(),
      authStore: useAuthStore(),
      nodePoolStore: useNodePoolStore(),
      clusterStore: useClusterStore(),
      metricsStore: useMetricsStore(),
      dataReady: false as boolean,
      hideNodePoolsFilter: false as boolean,
      allocated: 0 as number,
      total: 0 as number,
      quota: 0 as number,

      pendingWorkloads: 0 as number,
      myNumber2: 0 as number,
      splineSeries: {} as IColumnChartSeries,
      columnSeries: {} as IColumnChartSeries,
      // data for stack graph level 0
      categories: [] as string[],
      quotaByNodePool: [] as IChartArrayItemConfig[],
      allocationByNodePool: [] as IChartArrayItemConfig[],

      // data for multi bar graph level 0
      pendingsByNodePool: [] as IChartArrayItemConfig[],
      lowestRatioAllocationDepartmentsArray: [] as IChartArrayItemConfig[],
      lowestRatioAllocationProjectsArray: [] as IChartArrayItemConfig[],

      // drilldown level 1
      lowestRatioAllocationProjectsArrayByDepartments: [] as IChartArrayItemConfig[],
      quotaByNodePoolByDepartments: [] as IChartArrayItemConfig[],
      allocationByNodePoolByDepartments: [] as IChartArrayItemConfig[],
      pendingsByNodePoolByDepartments: [] as IChartArrayItemConfig[],

      departmentsMap: {} as Record<string, string>,
      projectsMap: {} as Record<string, string>,
      nodepools: [] as INodePool[],
      departments: [] as Department[],
      projects: [] as Project[],
      filterBy: [] as IFilterModel[],
      isOnlyDefaultNodePool: false as boolean,
    };
  },
  async created() {
    this.loadFilters();

    try {
      this.isOnlyDefaultNodePool = await this.nodePoolStore.isOnlyDefaultNodePoolByClusterId(this.clusterId);
    } catch (e) {
      console.error(e);
      this.hideNodePoolsFilter = true;
    }
    // As default get last week data
    const lastWeekDate = dateUtil.adjustDateBy(TimeUnit.week, new Date(), -1);
    await this.loadData({}, lastWeekDate.toUTCString(), new Date().toUTCString());
    this.appStore.setPageLoading(false);
  },
  computed: {
    clusterFilterBy(): IFilterBy {
      return {
        clusterUuid: this.clusterId,
      };
    },
    clusterId(): string {
      return this.clusterStore.selectedClusterId;
    },
    byNodePoolTitle(): string {
      return this.isOnlyDefaultNodePool ? "" : "by node pool";
    },
    filterOptions(): IFilterOption[] {
      return filterOptions.filter(
        (filterOption: IFilterOption) =>
          (this.isDepartmentEnabled || filterOption.field !== EFilterOption.Department) &&
          (!this.isOnlyDefaultNodePool || filterOption.field !== EFilterOption.Nodepool) &&
          !this.hideNodePoolsFilter,
      );
    },
    isDepartmentEnabled(): boolean {
      return this.settingsStore.isDepartmentEnabled as boolean;
    },
    CHART_COLOR_PALETTE(): typeof CHART_COLOR_PALETTE {
      return CHART_COLOR_PALETTE;
    },
    DRILL_DOWN_TITLES_DEPARTMENTS(): typeof DRILL_DOWN_TITLES_DEPARTMENTS {
      return DRILL_DOWN_TITLES_DEPARTMENTS;
    },
    DRILL_DOWN_TITLES() {
      const departmentEnabled: boolean = this.isDepartmentEnabled as boolean;
      return departmentEnabled ? DRILL_DOWN_TITLES : DRILL_DOWN_TITLES_PROJECTS;
    },
    SINGLE_STAT_COLOR_PALETTE() {
      return SINGLE_STAT_COLOR_PALETTE;
    },
    EChartType() {
      return EChartType;
    },
  },
  methods: {
    loadFilters(): void {
      this.filterBy = storageUtil.get<IFilterModel[] | null>(QUOTA_MANAGEMENT_FILTERS) ?? [];
    },
    setChartData(): void {
      // Data for single stats
      this.allocated = this.metricsStore.allocatedGpu;
      this.total = this.metricsStore.totalGpu;
      this.quota = this.metricsStore.quota;
      this.pendingWorkloads = this.metricsStore.pendingWorkloads;

      // Data for Stack charts with drill down
      this.quotaByNodePool = this.metricsStore.quotaClusterByNodePoolArray;
      this.allocationByNodePool = this.metricsStore.allocationClusterByNodePoolArray;
      this.pendingsByNodePool = this.metricsStore.pendingClusterByNodePoolArray;

      // Drill down data level 1 + 2 - by departments + by projects
      this.quotaByNodePoolByDepartments = this.metricsStore.quotaDepartmentsByNodePoolArray;
      this.allocationByNodePoolByDepartments = this.metricsStore.allocationDepartmentsByNodePoolArray;
      this.pendingsByNodePoolByDepartments = this.metricsStore.pendingDepartmentsByNodePoolArray;
      this.lowestRatioAllocationDepartmentsArray = this.metricsStore.lowestRatioAllocationDepartmentsArray;
      this.lowestRatioAllocationProjectsArrayByDepartments =
        this.metricsStore.lowestRatioAllocationProjectsArrayByDepartments;
      this.lowestRatioAllocationProjectsArray = this.metricsStore.lowestRatioAllocationProjectsArray;

      this.dataReady = true;
    },
    setTimeSeriesTimeData(): void {
      // set the time series data
      this.categories = this.metricsStore.quotaAllocationTimeSeriesDate.categories;
      this.splineSeries = this.metricsStore.quotaAllocationTimeSeriesDate.splineSeries;
      this.columnSeries = this.metricsStore.quotaAllocationTimeSeriesDate.columnSeries;
    },
    async onDateRangeChanged(range: DateRange): Promise<void> {
      await this.metricsStore.loadMetrics(
        this.clusterId,
        range.startDate,
        range.endDate,
        this.authStore.isAdmin,
        this.metricsStore.filter,
      );
      this.setTimeSeriesTimeData();
    },
    async loadData(metricsFilter = {}, startDate = "", endDate = ""): Promise<void> {
      try {
        await this.metricsStore.loadMetrics(this.clusterId, startDate, endDate, this.authStore.isAdmin, metricsFilter);
      } catch (e) {
        this.metricsStore.resetDataState();

        this.$q.notify(alertUtil.getError("Failed to load data"));
        console.error(e);
      } finally {
        this.setChartData();
        this.setTimeSeriesTimeData();
      }
    },
    async onFilterChange(filters: Array<IFilterModel>): Promise<void> {
      this.filterBy = [...filters];
      this.saveFiltersToStorage(filters);
      const metricsFilter: MetricsFilter = {} as MetricsFilter;
      filters.forEach((filterModel: IFilterModel) => {
        switch (filterModel.field) {
          case EFilterOption.Project:
            metricsFilter.project = this.projectsMap[filterModel.term];
            break;
          case EFilterOption.Department:
            metricsFilter.department = this.departmentsMap[filterModel.term];
            break;
          case EFilterOption.Nodepool:
            metricsFilter.nodePool = filterModel.term;
        }
      });

      await this.loadData(metricsFilter);
    },
    saveFiltersToStorage(filters: Array<IFilterModel>): void {
      storageUtil.save(QUOTA_MANAGEMENT_FILTERS, filters);
    },
    async getFilteredProjects(searchQuery: string): Promise<Array<string>> {
      if (this.projects.length === 0) {
        await orgUnitService
          .getProjects([filterUtil.getEqualsFilterString(ProjectFilterSortFields.ClusterId, this.clusterId)])
          .then((projects: Array<Project>) => {
            this.projects = projects;
            projects.forEach((project: Project) => {
              this.projectsMap[project.name] = project.id;
            });
          });
      }

      let options = [] as Array<string>;
      options = this.projects
        .map((project: Project) => project.name)
        .filter((name: string) => name.includes(searchQuery));
      return Promise.resolve(options);
    },
    async getFilteredDepartments(searchQuery: string): Promise<Array<string>> {
      if (this.departments.length === 0) {
        await orgUnitService
          .getDepartments([filterUtil.getEqualsFilterString(DepartmentFilterSortFields.ClusterId, this.clusterId)])
          .then((departments: Array<Department>) => {
            this.departments = departments;
            departments.forEach((department: Department) => {
              this.departmentsMap[department.name] = department.id;
            });
          });
      }

      let options = [] as Array<string>;
      options = this.departments
        .map((department: Department) => department.name)
        .filter((name: string) => name.includes(searchQuery));
      return Promise.resolve(options);
    },
    async getFilteredNodePools(searchQuery: string): Promise<Array<string>> {
      if (this.nodepools.length === 0) {
        await nodePoolService.getNodePools(this.clusterId).then((nodepools: Array<INodePool>) => {
          this.nodepools = nodepools;
        });
      }

      let options = [] as Array<string>;
      options = this.nodepools
        .map((nodePool: INodePool) => nodePool.name)
        .filter((name: string) => name.includes(searchQuery));
      return Promise.resolve(options);
    },
  },
  watch: {
    clusterId() {
      this.onFilterChange(this.filterBy);
    },
  },
});
</script>
<style lang="scss">
.dashboard-page-filter {
  background: $white;
  box-shadow: 0 2px 4px $black-15;
  height: 60px;
  display: flex;
  align-items: center;
}
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * identity-manager
 * An API for managing runai realms in mappers
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppTokenRequest
 */
export interface AppTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AppTokenRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AppTokenRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AppTokenRequest
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface AppTokenResponse
 */
export interface AppTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AppTokenResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AppTokenResponse
     */
    'id_token': string;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface ApplicationCreationRequest
 */
export interface ApplicationCreationRequest {
    /**
     * The name of the application. The name must be unique within the organization and can only contain lowercase alphanumeric characters and hyphens. It must start and end with a letter.
     * @type {string}
     * @memberof ApplicationCreationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ApplicationPatchRequest
 */
export interface ApplicationPatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPatchRequest
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface ApplicationPostResponse
 */
export interface ApplicationPostResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'secret': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostResponse
     */
    'clientId'?: string;
}
/**
 * 
 * @export
 * @interface AuthConfiguration
 */
export interface AuthConfiguration {
    /**
     * 
     * @type {string}
     * @memberof AuthConfiguration
     */
    'tenantName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfiguration
     */
    'authClientID': string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfiguration
     */
    'authHost': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthConfiguration
     */
    'isMultiTenant': boolean;
}
/**
 * 
 * @export
 * @interface ChangeUserPassword200Response
 */
export interface ChangeUserPassword200Response {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserPassword200Response
     */
    'currentPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserPassword200Response
     */
    'newPassword'?: string;
}
/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    'secret': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Client
     */
    'redirectUris'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof Client
     */
    'attributes'?: object;
}
/**
 * 
 * @export
 * @interface ClientAttributeCreationRequest
 */
export interface ClientAttributeCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'realm'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'mimir_header'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'tenant_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAttributeCreationRequest
     */
    'runai.cluster_id'?: string;
}
/**
 * 
 * @export
 * @interface ClientCreationRequest
 */
export interface ClientCreationRequest {
    /**
     * 
     * @type {ClientType}
     * @memberof ClientCreationRequest
     */
    'type': ClientType;
    /**
     * 
     * @type {string}
     * @memberof ClientCreationRequest
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreationRequest
     */
    'tenantId'?: string;
    /**
     * 
     * @type {ClientAttributeCreationRequest}
     * @memberof ClientCreationRequest
     */
    'attributes'?: ClientAttributeCreationRequest;
}
/**
 * 
 * @export
 * @interface ClientRedirectUrisUpdateRequest
 */
export interface ClientRedirectUrisUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientRedirectUrisUpdateRequest
     */
    'redirectUris': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClientType = {
    Agent: 'Agent',
    WorkloadsAuth: 'WorkloadsAuth',
    Application: 'Application'
} as const;

export type ClientType = typeof ClientType[keyof typeof ClientType];


/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface ConfigSamlResponse
 */
export interface ConfigSamlResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfigSamlResponse
     */
    'entity_id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigSamlResponse
     */
    'redirect_uri': string;
}
/**
 * 
 * @export
 * @interface CountUsers200Response
 */
export interface CountUsers200Response {
    /**
     * 
     * @type {number}
     * @memberof CountUsers200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface CreateInternalClient201Response
 */
export interface CreateInternalClient201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateInternalClient201Response
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface GetClientSecret200Response
 */
export interface GetClientSecret200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClientSecret200Response
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface Idp
 */
export interface Idp {
    /**
     * 
     * @type {string}
     * @memberof Idp
     */
    'alias': string;
    /**
     * 
     * @type {string}
     * @memberof Idp
     */
    'type': IdpTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Idp
     */
    'redirectUri': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Idp
     */
    'config': { [key: string]: string; };
}

export const IdpTypeEnum = {
    Saml: 'saml',
    Oidc: 'oidc',
    OpenshiftV4: 'openshift-v4'
} as const;

export type IdpTypeEnum = typeof IdpTypeEnum[keyof typeof IdpTypeEnum];

/**
 * 
 * @export
 * @interface IdpCreationRequest
 */
export interface IdpCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof IdpCreationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpCreationRequest
     */
    'type': IdpCreationRequestTypeEnum;
    /**
     * 
     * @type {SamlCreationData}
     * @memberof IdpCreationRequest
     */
    'samlData'?: SamlCreationData | null;
    /**
     * 
     * @type {OidcCreationData}
     * @memberof IdpCreationRequest
     */
    'oidcData'?: OidcCreationData | null;
    /**
     * 
     * @type {OcpCreationData}
     * @memberof IdpCreationRequest
     */
    'ocpData'?: OcpCreationData | null;
    /**
     * 
     * @type {Mappers}
     * @memberof IdpCreationRequest
     */
    'mappers'?: Mappers;
}

export const IdpCreationRequestTypeEnum = {
    Saml: 'saml',
    Oidc: 'oidc',
    OpenshiftV4: 'openshift-v4'
} as const;

export type IdpCreationRequestTypeEnum = typeof IdpCreationRequestTypeEnum[keyof typeof IdpCreationRequestTypeEnum];

/**
 * 
 * @export
 * @interface IdpPostResponse
 */
export interface IdpPostResponse {
    /**
     * 
     * @type {string}
     * @memberof IdpPostResponse
     */
    'alias': string;
}
/**
 * 
 * @export
 * @interface IdpResources
 */
export interface IdpResources {
    /**
     * 
     * @type {string}
     * @memberof IdpResources
     */
    'resourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpResources
     */
    'resource'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdpResources
     */
    'type'?: IdpResourcesTypeEnum;
}

export const IdpResourcesTypeEnum = {
    Url: 'url',
    File: 'file'
} as const;

export type IdpResourcesTypeEnum = typeof IdpResourcesTypeEnum[keyof typeof IdpResourcesTypeEnum];

/**
 * 
 * @export
 * @interface InternalUserCreationRequest
 */
export interface InternalUserCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof InternalUserCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserCreationRequest
     */
    'realm': string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserCreationRequest
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserCreationRequest
     */
    'resetPassword': boolean;
}
/**
 * 
 * @export
 * @interface Mappers
 */
export interface Mappers {
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'gid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'groups'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'supplementaryGroups'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mappers
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface OcpCreationData
 */
export interface OcpCreationData {
    /**
     * 
     * @type {string}
     * @memberof OcpCreationData
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OcpCreationData
     */
    'idpBaseUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OcpCreationData
     */
    'clientSecret': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OcpCreationData
     */
    'defaultScope'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OidcCreationData
 */
export interface OidcCreationData {
    /**
     * 
     * @type {string}
     * @memberof OidcCreationData
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof OidcCreationData
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof OidcCreationData
     */
    'discoverDocumentUrl': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OidcCreationData
     */
    'defaultScope'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RealmCreationRequest
 */
export interface RealmCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof RealmCreationRequest
     */
    'realm_name': string;
    /**
     * 
     * @type {string}
     * @memberof RealmCreationRequest
     */
    'tenant_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealmCreationRequest
     */
    'external_redirect_uri'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealmCreationRequest
     */
    'excludeGroupsFromTokens'?: boolean;
}
/**
 * 
 * @export
 * @interface RealmCreationResponse
 */
export interface RealmCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof RealmCreationResponse
     */
    'realm_name': string;
    /**
     * 
     * @type {string}
     * @memberof RealmCreationResponse
     */
    'domain': string;
    /**
     * 
     * @type {Array<Client>}
     * @memberof RealmCreationResponse
     */
    'clients': Array<Client>;
}
/**
 * 
 * @export
 * @interface RealmReconcileRequest
 */
export interface RealmReconcileRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RealmReconcileRequest
     */
    'force'?: boolean;
}
/**
 * 
 * @export
 * @interface RealmUpdateRequest
 */
export interface RealmUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RealmUpdateRequest
     */
    'excludeGroupsFromTokens': boolean;
}
/**
 * 
 * @export
 * @interface RealmUpdateResponse
 */
export interface RealmUpdateResponse {
    /**
     * 
     * @type {string}
     * @memberof RealmUpdateResponse
     */
    'tenant_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof RealmUpdateResponse
     */
    'excludeGroupsFromTokens': boolean;
}
/**
 * 
 * @export
 * @interface RegenerateApplicationSecret200Response
 */
export interface RegenerateApplicationSecret200Response {
    /**
     * 
     * @type {string}
     * @memberof RegenerateApplicationSecret200Response
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface RegenerateUserApplicationSecret200Response
 */
export interface RegenerateUserApplicationSecret200Response {
    /**
     * 
     * @type {string}
     * @memberof RegenerateUserApplicationSecret200Response
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface ResetUserPassword200Response
 */
export interface ResetUserPassword200Response {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPassword200Response
     */
    'tempPassword'?: string;
}
/**
 * 
 * @export
 * @interface SamlCreationData
 */
export interface SamlCreationData {
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'metadataXmlUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'metadataXmlFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'metadataXmlType'?: SamlCreationDataMetadataXmlTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SamlCreationData
     */
    'entityId'?: string;
}

export const SamlCreationDataMetadataXmlTypeEnum = {
    Url: 'url',
    File: 'file'
} as const;

export type SamlCreationDataMetadataXmlTypeEnum = typeof SamlCreationDataMetadataXmlTypeEnum[keyof typeof SamlCreationDataMetadataXmlTypeEnum];

/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'grantType'?: TokenRequestGrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     * @deprecated
     */
    'appID'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     * @deprecated
     */
    'appSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'redirectUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'clientID'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'clientSecret'?: string;
}

export const TokenRequestGrantTypeEnum = {
    AppToken: 'app_token',
    ClientCredentials: 'client_credentials',
    RefreshToken: 'refresh_token',
    ExchangeToken: 'exchange_token',
    Password: 'password'
} as const;

export type TokenRequestGrantTypeEnum = typeof TokenRequestGrantTypeEnum[keyof typeof TokenRequestGrantTypeEnum];

/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'idToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'externalToken'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isLocal': boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'groups'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserApp
 */
export interface UserApp {
    /**
     * 
     * @type {string}
     * @memberof UserApp
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserApp
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof UserApp
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserApp
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApp
     */
    'lastLogin': string | null;
}
/**
 * 
 * @export
 * @interface UserAppAdministration
 */
export interface UserAppAdministration {
    /**
     * 
     * @type {string}
     * @memberof UserAppAdministration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppAdministration
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppAdministration
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppAdministration
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppAdministration
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAppAdministration
     */
    'lastLogin': string | null;
}
/**
 * 
 * @export
 * @interface UserAppCreationRequest
 */
export interface UserAppCreationRequest {
    /**
     * The name of the user application. The name must be unique within the user\'s applications list and can only contain lowercase alphanumeric characters and hyphens. It must start and end with a letter.
     * @type {string}
     * @memberof UserAppCreationRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserAppPostResponse
 */
export interface UserAppPostResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAppPostResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppPostResponse
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppPostResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserAppPostResponse
     */
    'secret': string;
}
/**
 * 
 * @export
 * @interface UserChangePasswordRequest
 */
export interface UserChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * The email of the user. Must be a valid email address.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreationRequest
     */
    'resetPassword'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserPostResponse
 */
export interface UserPostResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPostResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPostResponse
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserPostResponse
     */
    'tempPassword': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UsersFilterSortFields = {
    Username: 'username',
    CreatedBy: 'createdBy',
    LastLogin: 'lastLogin',
    CreationTime: 'creationTime',
    LastUpdated: 'lastUpdated',
    Type: 'type'
} as const;

export type UsersFilterSortFields = typeof UsersFilterSortFields[keyof typeof UsersFilterSortFields];



/**
 * ApplicationsApi - axios parameter creator
 * @export
 */
export const ApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to create an application.
         * @summary Create an application.
         * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (applicationCreationRequest: ApplicationCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationCreationRequest' is not null or undefined
            assertParamExists('createApplication', 'applicationCreationRequest', applicationCreationRequest)
            const localVarPath = `/api/v1/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete an application by id.
         * @summary Delete an application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of an application by id.
         * @summary Get application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of applications.
         * @summary Get a list of applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of installer applications.
         * @summary Get a list of installer applications.
         * @param {string} [tenantId] if exists will return the relevant app for the tenant ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerApp: async (tenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/apps/installer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to regenerate the application secret by id.
         * @summary Regenerate an application secret.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateApplicationSecret: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('regenerateApplicationSecret', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}/secret`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the details of an application by id.
         * @summary Update application details by id.
         * @param {string} appId The application id to retrieve
         * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationById: async (appId: string, applicationPatchRequest?: ApplicationPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/apps/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Used to create an application.
         * @summary Create an application.
         * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(applicationCreationRequest: ApplicationCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(applicationCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete an application by id.
         * @summary Delete an application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the details of an application by id.
         * @summary Get application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of applications.
         * @summary Get a list of applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Application>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of installer applications.
         * @summary Get a list of installer applications.
         * @param {string} [tenantId] if exists will return the relevant app for the tenant ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerApp(tenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerApp(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to regenerate the application secret by id.
         * @summary Regenerate an application secret.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateApplicationSecret(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegenerateApplicationSecret200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateApplicationSecret(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the details of an application by id.
         * @summary Update application details by id.
         * @param {string} appId The application id to retrieve
         * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationById(appId: string, applicationPatchRequest?: ApplicationPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationById(appId, applicationPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationsApiFp(configuration)
    return {
        /**
         * Used to create an application.
         * @summary Create an application.
         * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(applicationCreationRequest: ApplicationCreationRequest, options?: any): AxiosPromise<ApplicationPostResponse> {
            return localVarFp.createApplication(applicationCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete an application by id.
         * @summary Delete an application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationById(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteApplicationById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of an application by id.
         * @summary Get application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationById(appId: string, options?: any): AxiosPromise<Application> {
            return localVarFp.getApplicationById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of applications.
         * @summary Get a list of applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(options?: any): AxiosPromise<Array<Application>> {
            return localVarFp.getApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of installer applications.
         * @summary Get a list of installer applications.
         * @param {string} [tenantId] if exists will return the relevant app for the tenant ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerApp(tenantId?: string, options?: any): AxiosPromise<ApplicationPostResponse> {
            return localVarFp.getInstallerApp(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to regenerate the application secret by id.
         * @summary Regenerate an application secret.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateApplicationSecret(appId: string, options?: any): AxiosPromise<RegenerateApplicationSecret200Response> {
            return localVarFp.regenerateApplicationSecret(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the details of an application by id.
         * @summary Update application details by id.
         * @param {string} appId The application id to retrieve
         * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationById(appId: string, applicationPatchRequest?: ApplicationPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateApplicationById(appId, applicationPatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
    /**
     * Used to create an application.
     * @summary Create an application.
     * @param {ApplicationCreationRequest} applicationCreationRequest Application object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public createApplication(applicationCreationRequest: ApplicationCreationRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).createApplication(applicationCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete an application by id.
     * @summary Delete an application by id.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public deleteApplicationById(appId: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).deleteApplicationById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of an application by id.
     * @summary Get application by id.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApplicationById(appId: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getApplicationById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of applications.
     * @summary Get a list of applications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getApplications(options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of installer applications.
     * @summary Get a list of installer applications.
     * @param {string} [tenantId] if exists will return the relevant app for the tenant ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public getInstallerApp(tenantId?: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).getInstallerApp(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to regenerate the application secret by id.
     * @summary Regenerate an application secret.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public regenerateApplicationSecret(appId: string, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).regenerateApplicationSecret(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the details of an application by id.
     * @summary Update application details by id.
     * @param {string} appId The application id to retrieve
     * @param {ApplicationPatchRequest} [applicationPatchRequest] Application object that needs to be updated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public updateApplicationById(appId: string, applicationPatchRequest?: ApplicationPatchRequest, options?: AxiosRequestConfig) {
        return ApplicationsApiFp(this.configuration).updateApplicationById(appId, applicationPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create internal client
         * @param {ClientCreationRequest} clientCreationRequest Client object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalClient: async (clientCreationRequest: ClientCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCreationRequest' is not null or undefined
            assertParamExists('createInternalClient', 'clientCreationRequest', clientCreationRequest)
            const localVarPath = `/internal/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete client
         * @param {string} id ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClient', 'id', id)
            const localVarPath = `/internal/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all clients
         * @param {string} [filter] Filter results by client attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients: async (filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get client secret
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientSecret: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClientSecret', 'id', id)
            const localVarPath = `/internal/clients/{id}/secret`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update client redirect uris by id
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedirectUris: async (id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRedirectUris', 'id', id)
            // verify required parameter 'clientRedirectUrisUpdateRequest' is not null or undefined
            assertParamExists('updateRedirectUris', 'clientRedirectUrisUpdateRequest', clientRedirectUrisUpdateRequest)
            const localVarPath = `/internal/clients/{id}/redirectUris`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientRedirectUrisUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create internal client
         * @param {ClientCreationRequest} clientCreationRequest Client object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalClient(clientCreationRequest: ClientCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInternalClient201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalClient(clientCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete client
         * @param {string} id ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all clients
         * @param {string} [filter] Filter results by client attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClients(filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Client>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClients(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get client secret
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientSecret(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientSecret200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientSecret(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update client redirect uris by id
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRedirectUris(id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRedirectUris(id, clientRedirectUrisUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary create internal client
         * @param {ClientCreationRequest} clientCreationRequest Client object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalClient(clientCreationRequest: ClientCreationRequest, options?: any): AxiosPromise<CreateInternalClient201Response> {
            return localVarFp.createInternalClient(clientCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete client
         * @param {string} id ID of the client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all clients
         * @param {string} [filter] Filter results by client attribute.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClients(filter?: string, options?: any): AxiosPromise<Array<Client>> {
            return localVarFp.getAllClients(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get client secret
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientSecret(id: string, options?: any): AxiosPromise<GetClientSecret200Response> {
            return localVarFp.getClientSecret(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update client redirect uris by id
         * @param {string} id The client \&quot;id\&quot; to retrieve
         * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedirectUris(id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateRedirectUris(id, clientRedirectUrisUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary create internal client
     * @param {ClientCreationRequest} clientCreationRequest Client object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public createInternalClient(clientCreationRequest: ClientCreationRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).createInternalClient(clientCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete client
     * @param {string} id ID of the client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public deleteClient(id: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).deleteClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all clients
     * @param {string} [filter] Filter results by client attribute.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getAllClients(filter?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getAllClients(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get client secret
     * @param {string} id The client \&quot;id\&quot; to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public getClientSecret(id: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).getClientSecret(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update client redirect uris by id
     * @param {string} id The client \&quot;id\&quot; to retrieve
     * @param {ClientRedirectUrisUpdateRequest} clientRedirectUrisUpdateRequest Updated Client redirect uris
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public updateRedirectUris(id: string, clientRedirectUrisUpdateRequest: ClientRedirectUrisUpdateRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).updateRedirectUris(id, clientRedirectUrisUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve Run:ai configuration settings.
         * @summary Get configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve Run:ai configuration settings.
         * @summary Get configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * Retrieve Run:ai configuration settings.
         * @summary Get configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantConfiguration(options?: any): AxiosPromise<AuthConfiguration> {
            return localVarFp.getTenantConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * Retrieve Run:ai configuration settings.
     * @summary Get configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getTenantConfiguration(options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).getTenantConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdpsApi - axios parameter creator
 * @export
 */
export const IdpsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary configure external idp
         * @param {IdpCreationRequest} idpCreationRequest idp configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdp: async (idpCreationRequest: IdpCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idpCreationRequest' is not null or undefined
            assertParamExists('createIdp', 'idpCreationRequest', idpCreationRequest)
            const localVarPath = `/api/v1/idps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idpCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdp: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('deleteIdp', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdp: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('getIdp', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get idp mappers
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpMappers: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('getIdpMappers', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}/mappers`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get idp resources
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpResources: async (idp: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('getIdpResources', 'idp', idp)
            const localVarPath = `/api/v1/idps/{idp}/resources`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get external idps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/idps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update external idp
         * @param {string} idp The alias of the idp
         * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdp: async (idp: string, idpCreationRequest: IdpCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('updateIdp', 'idp', idp)
            // verify required parameter 'idpCreationRequest' is not null or undefined
            assertParamExists('updateIdp', 'idpCreationRequest', idpCreationRequest)
            const localVarPath = `/api/v1/idps/{idp}`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idpCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update idp mappers
         * @param {string} idp The alias of the idp
         * @param {Mappers} mappers map of our mappers and their source in the external idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdpMappers: async (idp: string, mappers: Mappers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idp' is not null or undefined
            assertParamExists('updateIdpMappers', 'idp', idp)
            // verify required parameter 'mappers' is not null or undefined
            assertParamExists('updateIdpMappers', 'mappers', mappers)
            const localVarPath = `/api/v1/idps/{idp}/mappers`
                .replace(`{${"idp"}}`, encodeURIComponent(String(idp)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mappers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdpsApi - functional programming interface
 * @export
 */
export const IdpsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdpsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary configure external idp
         * @param {IdpCreationRequest} idpCreationRequest idp configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIdp(idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIdp(idpCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIdp(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIdp(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdp(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdp(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get idp mappers
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdpMappers(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mappers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdpMappers(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get idp resources
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdpResources(idp: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdpResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdpResources(idp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get external idps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Idp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update external idp
         * @param {string} idp The alias of the idp
         * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdp(idp: string, idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdp(idp, idpCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update idp mappers
         * @param {string} idp The alias of the idp
         * @param {Mappers} mappers map of our mappers and their source in the external idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdpMappers(idp: string, mappers: Mappers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdpMappers(idp, mappers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdpsApi - factory interface
 * @export
 */
export const IdpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdpsApiFp(configuration)
    return {
        /**
         * 
         * @summary configure external idp
         * @param {IdpCreationRequest} idpCreationRequest idp configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIdp(idpCreationRequest: IdpCreationRequest, options?: any): AxiosPromise<IdpPostResponse> {
            return localVarFp.createIdp(idpCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdp(idp: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIdp(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get external idp
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdp(idp: string, options?: any): AxiosPromise<Idp> {
            return localVarFp.getIdp(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get idp mappers
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpMappers(idp: string, options?: any): AxiosPromise<Mappers> {
            return localVarFp.getIdpMappers(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get idp resources
         * @param {string} idp The alias of the idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdpResources(idp: string, options?: any): AxiosPromise<IdpResources> {
            return localVarFp.getIdpResources(idp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get external idps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdps(options?: any): AxiosPromise<Array<Idp>> {
            return localVarFp.getIdps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update external idp
         * @param {string} idp The alias of the idp
         * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdp(idp: string, idpCreationRequest: IdpCreationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateIdp(idp, idpCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update idp mappers
         * @param {string} idp The alias of the idp
         * @param {Mappers} mappers map of our mappers and their source in the external idp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdpMappers(idp: string, mappers: Mappers, options?: any): AxiosPromise<void> {
            return localVarFp.updateIdpMappers(idp, mappers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdpsApi - object-oriented interface
 * @export
 * @class IdpsApi
 * @extends {BaseAPI}
 */
export class IdpsApi extends BaseAPI {
    /**
     * 
     * @summary configure external idp
     * @param {IdpCreationRequest} idpCreationRequest idp configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public createIdp(idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).createIdp(idpCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete external idp
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public deleteIdp(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).deleteIdp(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get external idp
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdp(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdp(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get idp mappers
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdpMappers(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdpMappers(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get idp resources
     * @param {string} idp The alias of the idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdpResources(idp: string, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdpResources(idp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get external idps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public getIdps(options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).getIdps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update external idp
     * @param {string} idp The alias of the idp
     * @param {IdpCreationRequest} idpCreationRequest Updated IdP object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public updateIdp(idp: string, idpCreationRequest: IdpCreationRequest, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).updateIdp(idp, idpCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update idp mappers
     * @param {string} idp The alias of the idp
     * @param {Mappers} mappers map of our mappers and their source in the external idp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdpsApi
     */
    public updateIdpMappers(idp: string, mappers: Mappers, options?: AxiosRequestConfig) {
        return IdpsApiFp(this.configuration).updateIdpMappers(idp, mappers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginsApi - axios parameter creator
 * @export
 */
export const LoginsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get redirected to the login screen for your client 
         * @summary Get login url
         * @param {string} userAgent 
         * @param {string} [authenticationFlow] 
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginScreenURL: async (userAgent: string, authenticationFlow?: string, redirectUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAgent' is not null or undefined
            assertParamExists('getLoginScreenURL', 'userAgent', userAgent)
            const localVarPath = `/api/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authenticationFlow !== undefined) {
                localVarQueryParameter['authentication_flow'] = authenticationFlow;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirect_url'] = redirectUrl;
            }

            if (userAgent !== undefined && userAgent !== null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginsApi - functional programming interface
 * @export
 */
export const LoginsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get redirected to the login screen for your client 
         * @summary Get login url
         * @param {string} userAgent 
         * @param {string} [authenticationFlow] 
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginScreenURL(userAgent: string, authenticationFlow?: string, redirectUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginScreenURL(userAgent, authenticationFlow, redirectUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginsApi - factory interface
 * @export
 */
export const LoginsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginsApiFp(configuration)
    return {
        /**
         * Get redirected to the login screen for your client 
         * @summary Get login url
         * @param {string} userAgent 
         * @param {string} [authenticationFlow] 
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginScreenURL(userAgent: string, authenticationFlow?: string, redirectUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLoginScreenURL(userAgent, authenticationFlow, redirectUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginsApi - object-oriented interface
 * @export
 * @class LoginsApi
 * @extends {BaseAPI}
 */
export class LoginsApi extends BaseAPI {
    /**
     * Get redirected to the login screen for your client 
     * @summary Get login url
     * @param {string} userAgent 
     * @param {string} [authenticationFlow] 
     * @param {string} [redirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginsApi
     */
    public getLoginScreenURL(userAgent: string, authenticationFlow?: string, redirectUrl?: string, options?: AxiosRequestConfig) {
        return LoginsApiFp(this.configuration).getLoginScreenURL(userAgent, authenticationFlow, redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealmApi - axios parameter creator
 * @export
 */
export const RealmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new realm for runai tenant. 
         * @summary Create runai realm
         * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRealm: async (realmCreationRequest: RealmCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realmCreationRequest' is not null or undefined
            assertParamExists('createRealm', 'realmCreationRequest', realmCreationRequest)
            const localVarPath = `/internal/realms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realmCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete realm
         * @param {string} realm The name of the realm
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRealm: async (realm: string, isHardDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realm' is not null or undefined
            assertParamExists('deleteRealm', 'realm', realm)
            const localVarPath = `/internal/realms/{realm}`
                .replace(`{${"realm"}}`, encodeURIComponent(String(realm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isHardDelete !== undefined) {
                localVarQueryParameter['isHardDelete'] = isHardDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new realm for runai tenant. 
         * @summary Reconcile runai realm
         * @param {string} realm The name of the realm
         * @param {RealmReconcileRequest} [realmReconcileRequest] how to run reconcile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileRealm: async (realm: string, realmReconcileRequest?: RealmReconcileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realm' is not null or undefined
            assertParamExists('reconcileRealm', 'realm', realm)
            const localVarPath = `/internal/realms/{realm}/reconcile`
                .replace(`{${"realm"}}`, encodeURIComponent(String(realm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realmReconcileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update realm
         * @param {RealmUpdateRequest} realmUpdateRequest The content of the updated realm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRealm: async (realmUpdateRequest: RealmUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realmUpdateRequest' is not null or undefined
            assertParamExists('updateRealm', 'realmUpdateRequest', realmUpdateRequest)
            const localVarPath = `/api/v1/idm/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realmUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealmApi - functional programming interface
 * @export
 */
export const RealmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealmApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new realm for runai tenant. 
         * @summary Create runai realm
         * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRealm(realmCreationRequest: RealmCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealmCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRealm(realmCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete realm
         * @param {string} realm The name of the realm
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRealm(realm: string, isHardDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRealm(realm, isHardDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new realm for runai tenant. 
         * @summary Reconcile runai realm
         * @param {string} realm The name of the realm
         * @param {RealmReconcileRequest} [realmReconcileRequest] how to run reconcile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reconcileRealm(realm: string, realmReconcileRequest?: RealmReconcileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reconcileRealm(realm, realmReconcileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update realm
         * @param {RealmUpdateRequest} realmUpdateRequest The content of the updated realm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRealm(realmUpdateRequest: RealmUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealmUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRealm(realmUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealmApi - factory interface
 * @export
 */
export const RealmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealmApiFp(configuration)
    return {
        /**
         * Create a new realm for runai tenant. 
         * @summary Create runai realm
         * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRealm(realmCreationRequest: RealmCreationRequest, options?: any): AxiosPromise<RealmCreationResponse> {
            return localVarFp.createRealm(realmCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete realm
         * @param {string} realm The name of the realm
         * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRealm(realm: string, isHardDelete?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRealm(realm, isHardDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new realm for runai tenant. 
         * @summary Reconcile runai realm
         * @param {string} realm The name of the realm
         * @param {RealmReconcileRequest} [realmReconcileRequest] how to run reconcile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileRealm(realm: string, realmReconcileRequest?: RealmReconcileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reconcileRealm(realm, realmReconcileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update realm
         * @param {RealmUpdateRequest} realmUpdateRequest The content of the updated realm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRealm(realmUpdateRequest: RealmUpdateRequest, options?: any): AxiosPromise<RealmUpdateResponse> {
            return localVarFp.updateRealm(realmUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealmApi - object-oriented interface
 * @export
 * @class RealmApi
 * @extends {BaseAPI}
 */
export class RealmApi extends BaseAPI {
    /**
     * Create a new realm for runai tenant. 
     * @summary Create runai realm
     * @param {RealmCreationRequest} realmCreationRequest The tenant on behalf realm will be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealmApi
     */
    public createRealm(realmCreationRequest: RealmCreationRequest, options?: AxiosRequestConfig) {
        return RealmApiFp(this.configuration).createRealm(realmCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete realm
     * @param {string} realm The name of the realm
     * @param {boolean} [isHardDelete] Boolean indicator for soft/hard delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealmApi
     */
    public deleteRealm(realm: string, isHardDelete?: boolean, options?: AxiosRequestConfig) {
        return RealmApiFp(this.configuration).deleteRealm(realm, isHardDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new realm for runai tenant. 
     * @summary Reconcile runai realm
     * @param {string} realm The name of the realm
     * @param {RealmReconcileRequest} [realmReconcileRequest] how to run reconcile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealmApi
     */
    public reconcileRealm(realm: string, realmReconcileRequest?: RealmReconcileRequest, options?: AxiosRequestConfig) {
        return RealmApiFp(this.configuration).reconcileRealm(realm, realmReconcileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update realm
     * @param {RealmUpdateRequest} realmUpdateRequest The content of the updated realm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealmApi
     */
    public updateRealm(realmUpdateRequest: RealmUpdateRequest, options?: AxiosRequestConfig) {
        return RealmApiFp(this.configuration).updateRealm(realmUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokensApi - axios parameter creator
 * @export
 */
export const TokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve access token for an application. The application token is retrieved from the authorization server. This endpoint is deprecated.  Use /api/v1/token with the grantType parameter set to app_token instead, with AppID and appSecret set accordingly to get an application token 
         * @summary get application token
         * @param {AppTokenRequest} [appTokenRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appToken: async (appTokenRequest?: AppTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/k8s/auth/oauth/apptoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to create application tokens. Select a token using the `grant_type` parameter.
         * @summary Create an application token.
         * @param {string} [userAgent] 
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantToken: async (userAgent?: string, tokenRequest?: TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userAgent !== undefined && userAgent !== null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokensApi - functional programming interface
 * @export
 */
export const TokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokensApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve access token for an application. The application token is retrieved from the authorization server. This endpoint is deprecated.  Use /api/v1/token with the grantType parameter set to app_token instead, with AppID and appSecret set accordingly to get an application token 
         * @summary get application token
         * @param {AppTokenRequest} [appTokenRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async appToken(appTokenRequest?: AppTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appToken(appTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to create application tokens. Select a token using the `grant_type` parameter.
         * @summary Create an application token.
         * @param {string} [userAgent] 
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantToken(userAgent?: string, tokenRequest?: TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantToken(userAgent, tokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokensApi - factory interface
 * @export
 */
export const TokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokensApiFp(configuration)
    return {
        /**
         * Retrieve access token for an application. The application token is retrieved from the authorization server. This endpoint is deprecated.  Use /api/v1/token with the grantType parameter set to app_token instead, with AppID and appSecret set accordingly to get an application token 
         * @summary get application token
         * @param {AppTokenRequest} [appTokenRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        appToken(appTokenRequest?: AppTokenRequest, options?: any): AxiosPromise<AppTokenResponse> {
            return localVarFp.appToken(appTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to create application tokens. Select a token using the `grant_type` parameter.
         * @summary Create an application token.
         * @param {string} [userAgent] 
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantToken(userAgent?: string, tokenRequest?: TokenRequest, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.grantToken(userAgent, tokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
export class TokensApi extends BaseAPI {
    /**
     * Retrieve access token for an application. The application token is retrieved from the authorization server. This endpoint is deprecated.  Use /api/v1/token with the grantType parameter set to app_token instead, with AppID and appSecret set accordingly to get an application token 
     * @summary get application token
     * @param {AppTokenRequest} [appTokenRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public appToken(appTokenRequest?: AppTokenRequest, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).appToken(appTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to create application tokens. Select a token using the `grant_type` parameter.
     * @summary Create an application token.
     * @param {string} [userAgent] 
     * @param {TokenRequest} [tokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    public grantToken(userAgent?: string, tokenRequest?: TokenRequest, options?: AxiosRequestConfig) {
        return TokensApiFp(this.configuration).grantToken(userAgent, tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApplicationsApi - axios parameter creator
 * @export
 */
export const UserApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to create a user application.
         * @summary Create a user application.
         * @param {UserAppCreationRequest} userAppCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApplication: async (userAppCreationRequest: UserAppCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAppCreationRequest' is not null or undefined
            assertParamExists('createUserApplication', 'userAppCreationRequest', userAppCreationRequest)
            const localVarPath = `/api/v1/user-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAppCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to user a user application by id.
         * @summary Delete a user application byid.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApplicationById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteUserApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/user-applications/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete a user application by id for adminstrations.
         * @summary Delete a user application by id for adminstrations.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApplicationByIdAdministration: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteUserApplicationByIdAdministration', 'appId', appId)
            const localVarPath = `/api/v1/administration/user-applications/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of a user\'s application by app id.
         * @summary Get user application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApplicationById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getUserApplicationById', 'appId', appId)
            const localVarPath = `/api/v1/user-applications/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of the users applications.
         * @summary Get a list of users applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApplications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of all users applications.
         * @summary Get a list of all users applications.
         * @param {string} [clientId] Filter results by clientId
         * @param {string} [createdBy] Filter results by creatdBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApplicationsAdministration: async (clientId?: string, createdBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administration/user-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to regenerate the user application secret by id.
         * @summary Regenerate a user application secret.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserApplicationSecret: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('regenerateUserApplicationSecret', 'appId', appId)
            const localVarPath = `/api/v1/user-applications/{appId}/secret`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApplicationsApi - functional programming interface
 * @export
 */
export const UserApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Used to create a user application.
         * @summary Create a user application.
         * @param {UserAppCreationRequest} userAppCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserApplication(userAppCreationRequest: UserAppCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserApplication(userAppCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to user a user application by id.
         * @summary Delete a user application byid.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserApplicationById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserApplicationById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete a user application by id for adminstrations.
         * @summary Delete a user application by id for adminstrations.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserApplicationByIdAdministration(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserApplicationByIdAdministration(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the details of a user\'s application by app id.
         * @summary Get user application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApplicationById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApplicationById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of the users applications.
         * @summary Get a list of users applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApplications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of all users applications.
         * @summary Get a list of all users applications.
         * @param {string} [clientId] Filter results by clientId
         * @param {string} [createdBy] Filter results by creatdBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApplicationsAdministration(clientId?: string, createdBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAppAdministration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApplicationsAdministration(clientId, createdBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to regenerate the user application secret by id.
         * @summary Regenerate a user application secret.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateUserApplicationSecret(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegenerateUserApplicationSecret200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateUserApplicationSecret(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApplicationsApi - factory interface
 * @export
 */
export const UserApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApplicationsApiFp(configuration)
    return {
        /**
         * Used to create a user application.
         * @summary Create a user application.
         * @param {UserAppCreationRequest} userAppCreationRequest Application object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserApplication(userAppCreationRequest: UserAppCreationRequest, options?: any): AxiosPromise<UserAppPostResponse> {
            return localVarFp.createUserApplication(userAppCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to user a user application by id.
         * @summary Delete a user application byid.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApplicationById(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserApplicationById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete a user application by id for adminstrations.
         * @summary Delete a user application by id for adminstrations.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserApplicationByIdAdministration(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserApplicationByIdAdministration(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of a user\'s application by app id.
         * @summary Get user application by id.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApplicationById(appId: string, options?: any): AxiosPromise<UserApp> {
            return localVarFp.getUserApplicationById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of the users applications.
         * @summary Get a list of users applications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApplications(options?: any): AxiosPromise<Array<UserApp>> {
            return localVarFp.getUserApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of all users applications.
         * @summary Get a list of all users applications.
         * @param {string} [clientId] Filter results by clientId
         * @param {string} [createdBy] Filter results by creatdBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApplicationsAdministration(clientId?: string, createdBy?: string, options?: any): AxiosPromise<Array<UserAppAdministration>> {
            return localVarFp.getUserApplicationsAdministration(clientId, createdBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to regenerate the user application secret by id.
         * @summary Regenerate a user application secret.
         * @param {string} appId The application id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateUserApplicationSecret(appId: string, options?: any): AxiosPromise<RegenerateUserApplicationSecret200Response> {
            return localVarFp.regenerateUserApplicationSecret(appId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApplicationsApi - object-oriented interface
 * @export
 * @class UserApplicationsApi
 * @extends {BaseAPI}
 */
export class UserApplicationsApi extends BaseAPI {
    /**
     * Used to create a user application.
     * @summary Create a user application.
     * @param {UserAppCreationRequest} userAppCreationRequest Application object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public createUserApplication(userAppCreationRequest: UserAppCreationRequest, options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).createUserApplication(userAppCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to user a user application by id.
     * @summary Delete a user application byid.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public deleteUserApplicationById(appId: string, options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).deleteUserApplicationById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete a user application by id for adminstrations.
     * @summary Delete a user application by id for adminstrations.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public deleteUserApplicationByIdAdministration(appId: string, options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).deleteUserApplicationByIdAdministration(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of a user\'s application by app id.
     * @summary Get user application by id.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public getUserApplicationById(appId: string, options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).getUserApplicationById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of the users applications.
     * @summary Get a list of users applications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public getUserApplications(options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).getUserApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of all users applications.
     * @summary Get a list of all users applications.
     * @param {string} [clientId] Filter results by clientId
     * @param {string} [createdBy] Filter results by creatdBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public getUserApplicationsAdministration(clientId?: string, createdBy?: string, options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).getUserApplicationsAdministration(clientId, createdBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to regenerate the user application secret by id.
     * @summary Regenerate a user application secret.
     * @param {string} appId The application id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApplicationsApi
     */
    public regenerateUserApplicationSecret(appId: string, options?: AxiosRequestConfig) {
        return UserApplicationsApiFp(this.configuration).regenerateUserApplicationSecret(appId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary change user password
         * @param {UserChangePasswordRequest} userChangePasswordRequest Password to change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (userChangePasswordRequest: UserChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userChangePasswordRequest' is not null or undefined
            assertParamExists('changeUserPassword', 'userChangePasswordRequest', userChangePasswordRequest)
            const localVarPath = `/api/v1/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * count users
         * @summary Count users
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUsers: async (filterBy?: Array<string>, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create internal user
         * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntenralUser: async (internalUserCreationRequest: InternalUserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'internalUserCreationRequest' is not null or undefined
            assertParamExists('createIntenralUser', 'internalUserCreationRequest', internalUserCreationRequest)
            const localVarPath = `/internal/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(internalUserCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to create a local platform user.
         * @summary Create a local user.
         * @param {UserCreationRequest} userCreationRequest User object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreationRequest: UserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreationRequest' is not null or undefined
            assertParamExists('createUser', 'userCreationRequest', userCreationRequest)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete a user by id.
         * @summary Delete a user by id.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserById', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a user\'s groups that have access roles.
         * @summary Get a users access groups.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessGroups: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUserAccessGroups', 'email', email)
            const localVarPath = `/internal/users/{email}/accessGroups`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a user\'s details by id.
         * @summary Get a user by id.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of platform users.
         * @summary Get users.
         * @param {string} [filter] Filter results by user attribute.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
         * @param {UsersFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (filter?: string, filterBy?: Array<string>, sortBy?: UsersFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to force a user to logout.
         * @summary Logout a user.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('logoutUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/logout`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to to reset a user\'s password.
         * @summary Reset a user\'s password.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetUserPassword', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary change user password
         * @param {UserChangePasswordRequest} userChangePasswordRequest Password to change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(userChangePasswordRequest: UserChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeUserPassword200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(userChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * count users
         * @summary Count users
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUsers(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countUsers(filterBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create internal user
         * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntenralUser(internalUserCreationRequest: InternalUserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntenralUser(internalUserCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to create a local platform user.
         * @summary Create a local user.
         * @param {UserCreationRequest} userCreationRequest User object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete a user by id.
         * @summary Delete a user by id.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a user\'s groups that have access roles.
         * @summary Get a users access groups.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccessGroups(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccessGroups(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a user\'s details by id.
         * @summary Get a user by id.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of platform users.
         * @summary Get users.
         * @param {string} [filter] Filter results by user attribute.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
         * @param {UsersFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(filter?: string, filterBy?: Array<string>, sortBy?: UsersFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(filter, filterBy, sortBy, sortOrder, offset, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to force a user to logout.
         * @summary Logout a user.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to to reset a user\'s password.
         * @summary Reset a user\'s password.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPassword(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetUserPassword200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPassword(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary change user password
         * @param {UserChangePasswordRequest} userChangePasswordRequest Password to change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(userChangePasswordRequest: UserChangePasswordRequest, options?: any): AxiosPromise<ChangeUserPassword200Response> {
            return localVarFp.changeUserPassword(userChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * count users
         * @summary Count users
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUsers(filterBy?: Array<string>, search?: string, options?: any): AxiosPromise<CountUsers200Response> {
            return localVarFp.countUsers(filterBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create internal user
         * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntenralUser(internalUserCreationRequest: InternalUserCreationRequest, options?: any): AxiosPromise<UserPostResponse> {
            return localVarFp.createIntenralUser(internalUserCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to create a local platform user.
         * @summary Create a local user.
         * @param {UserCreationRequest} userCreationRequest User object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreationRequest: UserCreationRequest, options?: any): AxiosPromise<UserPostResponse> {
            return localVarFp.createUser(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete a user by id.
         * @summary Delete a user by id.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a user\'s groups that have access roles.
         * @summary Get a users access groups.
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccessGroups(email: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getUserAccessGroups(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a user\'s details by id.
         * @summary Get a user by id.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of platform users.
         * @summary Get users.
         * @param {string} [filter] Filter results by user attribute.
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
         * @param {UsersFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(filter?: string, filterBy?: Array<string>, sortBy?: UsersFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(filter, filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to force a user to logout.
         * @summary Logout a user.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.logoutUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to to reset a user\'s password.
         * @summary Reset a user\'s password.
         * @param {string} userId The id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(userId: string, options?: any): AxiosPromise<ResetUserPassword200Response> {
            return localVarFp.resetUserPassword(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary change user password
     * @param {UserChangePasswordRequest} userChangePasswordRequest Password to change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeUserPassword(userChangePasswordRequest: UserChangePasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changeUserPassword(userChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * count users
     * @summary Count users
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public countUsers(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).countUsers(filterBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create internal user
     * @param {InternalUserCreationRequest} internalUserCreationRequest Internal user object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createIntenralUser(internalUserCreationRequest: InternalUserCreationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createIntenralUser(internalUserCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to create a local platform user.
     * @summary Create a local user.
     * @param {UserCreationRequest} userCreationRequest User object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(userCreationRequest: UserCreationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete a user by id.
     * @summary Delete a user by id.
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserById(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a user\'s groups that have access roles.
     * @summary Get a users access groups.
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserAccessGroups(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserAccessGroups(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a user\'s details by id.
     * @summary Get a user by id.
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of platform users.
     * @summary Get users.
     * @param {string} [filter] Filter results by user attribute.
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains. Dates are in ISO 8601 timestamp format and available for operators &lt;&#x3D;, &gt;&#x3D; and &#x3D;&#x3D;.
     * @param {UsersFilterSortFields} [sortBy] Sort results by a parameters.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(filter?: string, filterBy?: Array<string>, sortBy?: UsersFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(filter, filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to force a user to logout.
     * @summary Logout a user.
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public logoutUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).logoutUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to to reset a user\'s password.
     * @summary Reset a user\'s password.
     * @param {string} userId The id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetUserPassword(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetUserPassword(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



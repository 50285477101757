<template>
  <section class="runai-form-card-section">
    <template v-if="!loading && cardsList.length === 0">
      <section class="empty-box column justify-center items-center q-pa-md" v-if="emptyMessage">
        <runai-svg-icon :name="iconName" size="60" v-if="iconName" />
        <template v-if="canCreateEntity">
          <p class="empty-message text-center text-italic q-pt-md">{{ emptyMessage }}</p>
          <slot name="add-new">
            <q-btn
              :disable="disableCreateNew"
              class="create-new-btn"
              :aid="addNewBtnAid"
              flat
              color="primary"
              @click="createNew"
            >
              <q-icon left name="fa-regular fa-plus" size="14px"></q-icon>
              <div>new {{ entityName }}</div>
            </q-btn>
          </slot>
        </template>
        <template v-else>
          <slot name="no-roles" />
        </template>
      </section>
    </template>

    <template v-else>
      <section class="header">
        <div v-if="mainMessage" class="subheader q-pa-sm q-pb-md row">
          <div class="flex-1">
            {{ mainMessage }}
          </div>
          <q-badge v-if="badge" transparent align="middle" color="grey-4" text-color="grey-10"> {{ badge }} </q-badge>
        </div>

        <div v-if="allowActions" class="actions-container row justify-between items-center q-pl-sm q-pb-md">
          <div class="row q-gutter-x-sm">
            <q-select
              class="cards-sort-dropdown"
              v-if="Object.keys(sortOptions).length"
              dense
              outlined
              :options="selectableSortOptions"
              v-model="selectedSortOption"
            >
              <template #prepend>
                <q-icon size="14px" name="fa-solid fa-arrow-down-wide-short" />
              </template>
            </q-select>
            <runai-general-search
              :aid="entityName + '-search-card-input'"
              :search-term="searchTerm"
              @update-search-term="onSearch"
              :show-search-text="false"
            ></runai-general-search>
          </div>

          <slot name="add-new" v-if="entityName && canCreateEntity">
            <q-btn
              class="add-new-btn"
              flat
              color="primary"
              :aid="addNewBtnAid"
              :disable="disabled || disableCreateNew"
              @click="createNew"
            >
              <q-icon left name="fa-regular fa-plus" size="14px"></q-icon>
              <div>new {{ entityName }}</div>
            </q-btn>
          </slot>
        </div>
      </section>

      <div v-if="searchTerm && list.length === 0" class="column items-center q-pa-xl">
        <runai-svg-icon :name="filterNoMatchIcon" size="60" />
        <div class="q-pt-md">No {{ searchName }} match your filters</div>
      </div>

      <runai-card-list
        v-else
        class="cards-list-container"
        :list="list"
        :loading="loading"
        :multi-select="multiSelect"
        :selected-items="selectedCards"
        @changed="onCardClicked"
        :disabled="disabled"
      >
        <template #empty-data-display>
          <slot name="empty-data-display" />
        </template>
      </runai-card-list>
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// Components
import { RunaiCardList } from "@/components/common/runai-card-list";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";

// Models
import type { ICardListItem } from "@/components/common/runai-card-list";

// utils
import { spaceToDash, toLowerCase } from "@/utils/string.util";
import type { ISelectOption } from "@/models/global.model";
import { RunaiGeneralSearch } from "@/components/common/runai-general-search";
import { dateUtil } from "@/utils/date.util";

// stores
import { useAppStore } from "@/stores/app.store";

type TCardSortValues = "name" | "creation" | "recentUsage";

interface ICardSortOption extends ISelectOption {
  label: string;
  value: TCardSortValues;
}

type TCardSortFunction = (a: ICardListItem, b: ICardListItem) => number;

export interface ICardSupportedSortOptions {
  name?: boolean;
  creation?: boolean;
  recentUsage?: boolean;
}

type TSortFunctions = TCardSortValues | "default";

const sortFunctionsMap: Record<TSortFunctions, TCardSortFunction> = {
  name: (cardA: ICardListItem, cardB: ICardListItem) => {
    if (!cardB.sortInfo?.name || !cardA.sortInfo?.name) return 0;
    return cardA.sortInfo.name.localeCompare(cardB.sortInfo.name);
  },
  recentUsage: (cardA: ICardListItem, cardB: ICardListItem) =>
    dateUtil.dateToTimestamp(cardB.sortInfo?.recentUsage) - dateUtil.dateToTimestamp(cardA.sortInfo?.recentUsage),
  creation: (cardA: ICardListItem, cardB: ICardListItem) =>
    dateUtil.dateToTimestamp(cardB.sortInfo?.createdAt) - dateUtil.dateToTimestamp(cardA.sortInfo?.createdAt),
  default: () => 0,
};

export const START_FROM_SCRATCH_ID = "-1";

const allSortOptions: ICardSortOption[] = [
  { label: "Last used", value: "recentUsage" },
  { label: "Last created", value: "creation" },
  { label: "Name", value: "name" },
];

export default defineComponent({
  components: {
    RunaiCardList,
    RunaiSvgIcon,
    RunaiGeneralSearch,
  },
  emits: ["selected-card-changed", "create-new"],
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    emptyMessage: {
      type: String as PropType<string>,
      required: false,
    },
    mainMessage: {
      type: String as PropType<string>,
      required: false,
    },
    entityName: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    cardsList: {
      type: Array as PropType<Array<ICardListItem>>,
      required: true,
    },
    selectedCardsIds: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    iconName: {
      type: String as PropType<string>,
      required: false,
    },
    searchName: {
      type: String as PropType<string>,
      required: true,
    },
    multiSelect: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    allowActions: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disableCreateNew: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    sortOptions: {
      type: Object as PropType<ICardSupportedSortOptions>,
      default: () => ({}),
    },
    defaultSortOption: {
      type: String as PropType<TCardSortValues>,
      required: false,
    },
    canCreateEntity: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    badge: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      addNewBtnAid: `add-new-${toLowerCase(spaceToDash(this.entityName))}-btn` as string,
      searchTerm: "" as string,
      selectedSortOption: null as ICardSortOption | null,
    };
  },
  created() {
    this.setDefaultSortOption();
  },
  computed: {
    selectableSortOptions(): ICardSortOption[] {
      return allSortOptions.filter((sortOption) => !!this.sortOptions[sortOption.value]);
    },
    searchPlaceholder(): string {
      return `Search ${this.searchName}`;
    },
    selectedCards(): Array<string> {
      const ids: Set<string> = new Set(this.selectedCardsIds);
      return this.cardsList
        .filter((card: ICardListItem) => ids.has(String(card.id)) || card.locked)
        .map((card: ICardListItem) => card.id);
    },
    list(): Array<ICardListItem> {
      const sortFunc = this.selectedSortOption?.value
        ? sortFunctionsMap[this.selectedSortOption.value] || sortFunctionsMap.default
        : sortFunctionsMap.default;

      let startFromScarchCard: ICardListItem | null = null;

      const filteredAndSorted = this.cardsList
        .filter((card: ICardListItem) => {
          if (!this.searchTerm) {
            if (card.id === START_FROM_SCRATCH_ID) {
              startFromScarchCard = card;
              return false;
            }
            return true;
          }
          return this.isIncludeSearchTerm(card, this.searchTerm);
        })
        .sort(sortFunc);

      if (startFromScarchCard) {
        return [startFromScarchCard, ...filteredAndSorted];
      }

      return filteredAndSorted;
    },
    filterNoMatchIcon(): string {
      return this.appStore.isNewNavigationFeatureOn ? "filter-no-match-gray-new" : "filter-no-match-gray";
    },
    lockedCards(): Array<ICardListItem> {
      return this.cardsList.filter((card: ICardListItem) => card.locked);
    },
  },
  methods: {
    onCardClicked(selectedCardsIds: Array<string>): void {
      // locked cards are assets imposed by policy.
      // we only make them look as if they are selected but in reality they do not exists within the model.
      // therefore we need to filter them out
      this.$emit(
        "selected-card-changed",
        selectedCardsIds.filter((id: string) => !this.lockedCards.find((card: ICardListItem) => card.id === id)),
      );
    },
    createNew(): void {
      this.$emit("create-new");
    },
    onSearch(term: string | number | null): void {
      this.searchTerm = term === null ? "" : String(term);
    },
    isIncludeSearchTerm(card: ICardListItem, searchTerm: string): boolean {
      let values: Array<string> = card.searchValues || [];
      const searchTermLowercase: string = searchTerm.toLowerCase();
      return values.some((currVal: string) => currVal.toLowerCase().includes(searchTermLowercase));
    },
    setDefaultSortOption(): void {
      if (!this.defaultSortOption) this.selectedSortOption = allSortOptions[2];
      this.selectedSortOption =
        allSortOptions.find((sortOpt) => sortOpt.value === this.defaultSortOption) || allSortOptions[2];
    },
  },
});
</script>
<style lang="scss" scoped>
.runai-form-card-section {
  .search-icon {
    font-size: 16px;
  }
  .cards-sort-dropdown {
    width: 180px;
  }
}
</style>

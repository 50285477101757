<template>
  <runai-expansion-item label="Cluster" default-opened :section-invalid="sectionInvalid" aid="cluster-selection-section">
    <template #subheader>
      {{ clusterName || "none" }}
    </template>
    <div class="q-pt-sm q-pb-md">Set under which cluster to create this {{ entityName }}</div>
    <div class="row">
      <div class="col-10">
        <cluster-dropdown
          :disabled="disabled"
          :cluster-id="clusterId"
          @update:cluster-id="onClusterChanged"
          :rules="[isSupportedKnative, isClusterConnected]"
          disabled-tooltip-text="This can't be modified"
        />
      </div>
    </div>
  </runai-expansion-item>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

//store
import { useClusterStore } from "@/stores/cluster.store";

//cmps
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { ClusterDropdown } from "@/components/cluster/cluster-selection/cluster-dropdown/";

// utils
import { clusterUtil } from "@/utils/cluster.util";

// models
import type { IReadinessStatus } from "@/models/researcher.model";

// services
import { whoamiService } from "@/services/cluster/whoami.service/whoami.service";

// constants
import { errorMessages } from "@/common/error-message.constant";

export default defineComponent({
  name: "cluster-selection-section",
  components: { ClusterDropdown, RunaiExpansionItem },
  emits: ["update:cluster-id", "is-section-invalid"],
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    entityName: {
      type: String as PropType<string>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    knativeRequired: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    clusterConnectivityRequired: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      clusterStore: useClusterStore(),
    };
  },
  computed: {
    sectionInvalid(): boolean {
      return this.clusterName === null;
    },
    clusterName(): string | null {
      return this.clusterStore.clusterList.find((cluster) => cluster.uuid === this.clusterId)?.name || null;
    },
  },
  methods: {
    onClusterChanged(clusterId: string): void {
      this.$emit("update:cluster-id", clusterId);
    },
    async isClusterConnected(selectedCluster: { label: string; value: string } | null): Promise<boolean | string> {
      if (!this.clusterConnectivityRequired || !selectedCluster) return true;

      const clusterErrorMessage = errorMessages.CLUSTER_NOT_CONNECTED;
      const currentCluster = this.clusterStore.getClusterById(selectedCluster?.value);

      if (!currentCluster || !currentCluster.domain) return clusterErrorMessage;

      const res: IReadinessStatus = await whoamiService.isCurrentClusterConnected(currentCluster.domain);
      return res.connected ? true : clusterErrorMessage;
    },
    isSupportedKnative(selectedCluster: { label: string; value: string } | null): boolean | string {
      if (!this.knativeRequired || !selectedCluster) return true;

      const currentCluster = this.clusterStore.getClusterById(selectedCluster?.value);
      if (!currentCluster) return true;

      if (clusterUtil.isKnativeInstalledInCluster(currentCluster)) return true;

      return errorMessages.KNATIVE_IS_NOT_INSTALLED_SELECTED_CLUSTER;
    },
  },
  watch: {
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
    clusterId: {
      handler(clusterId: string): void {
        this.clusterStore.setSelectedClusterById(clusterId);
      },
    },
  },
});
</script>

<style scoped lang="scss"></style>
